import React, { useState, useEffect } from "react";
import { db } from "../../config/_firebase";

import { Drawer, Checkbox, Divider, Tag, Row, Button } from "antd";
import { ClearOutlined, CheckOutlined } from "@ant-design/icons";

export default function FilterSidebar({ adminId, isVisible, toggleVisibility, filterType, handleFilter, handleFilterChange, showUnread, showAssistanceRequired }) {
	const [tags, setTags] = useState([]);
	const [selectedTags, setSelectedTags] = React.useState([]);

	useEffect(() => {
		const ref = db.ref(`v2_userChats/${adminId}`);
		ref.on("value", (snapshot) => {
			if (snapshot) {
				const contacts = Object.values(snapshot.val());
				const tagStrings = contacts.map(({ tags }) => tags).filter((str) => str && str.trim() !== "");
				const uniqueTags = Array.from(
					new Set(
						tagStrings
							.join("~")
							.split("~")
							.filter((str) => str !== "")
					)
				);
				setTags(uniqueTags);
			}
		});

		return () => {
			ref.off();
		};
	}, [adminId]);

	function handleClearFilter() {
		handleFilter("tags", []);
		setSelectedTags([]);
	}

	const handleTagSelection = (tag, checked) => {
		const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
		setSelectedTags(nextSelectedTags);
	};

	const handleChange = ({ target: { name, checked } }) => {
		// const type = checked ? name : "";
		// handleFilter(type);
		handleFilterChange(name, checked);
	};

	return (
		<Drawer
			title={<div style={{ color: "#393E46", fontWeight: 600, paddingTop: "2em" }}>FILTERS</div>}
			visible={isVisible}
			closable={false}
			maskClosable
			onClose={toggleVisibility}>
			<div>
				<Checkbox checked={showUnread} name="unread" onChange={handleChange}>
					Only Show Unread
				</Checkbox>
			</div>
			<div style={{ paddingTop: "2em" }}>
				<Checkbox
					checked={showAssistanceRequired}
					name="assistanceRequired"
					onChange={handleChange}>
					Only Show Assistance Required
				</Checkbox>
			</div>
			<Divider />
			{tags.length > 0 ? (
				<div>
					{tags.map((tag) => (
						<Tag.CheckableTag
							key={tag}
							color="blue"
							checked={selectedTags.includes(tag)}
							onChange={(checked) => handleTagSelection(tag, checked)}>
							{tag}
						</Tag.CheckableTag>
					))}
					<Row style={{ marginTop: "1.5em" }} justify="space-between">
						<Button icon={<ClearOutlined />} disabled={selectedTags <= 0} onClick={handleClearFilter}>
							Clear
						</Button>
						<Button
							icon={<CheckOutlined />}
							type="primary"
							onClick={() => handleFilter("tags", selectedTags)}
							disabled={selectedTags <= 0}>
							Apply Tag Filters
						</Button>
					</Row>
					<Divider />
				</div>
			) : (
				<span color="#dddddd">No Tags to filter</span>
			)}
		</Drawer>
	);
}
