import React from "react";
import { Skeleton, Card } from "antd";

export default function SkeletonCard() {
	return (
		<Card style={{ margin: "0.5em" }}>
			<Skeleton loading active></Skeleton>
		</Card>
	);
}
