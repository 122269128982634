import React, { useContext, useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { db } from "../../config/_firebase.js";
import ChatWindowV2 from "./ChatWindowV2.jsx";
import { myContext } from "./myContext";
import algoliaIndex from "../../config/_algolia";
import SearchResults from "./SearchResults.jsx";

const adminUID = localStorage.getItem("adminUID");

const performAlgoliaSearch = async (value) => {
    const { hits } = await algoliaIndex.search(value, {
        restrictSearchableAttributes: ["messages", "name", "phone"],
        attributesToRetrieve: ["objectID", "name", "contactID", "timestamp"],
        hitsPerPage: 100,
        highlightPreTag: "<mark>",
        highlightPostTag: "</mark>",
    });
    const latestMessages = hits.map(
        ({
            _highlightResult: { messages, name, phone },
            contactID: contactId,
            objectID: chatId,
            timestamp,
        }) => {
            const searchedMessageIndex = messages.reduceRight(
                (prev, { matchLevel }, currentIndex) => {
                    if (prev > -1) return prev;
                    if (matchLevel === "full") return currentIndex;
                    return -1;
                },
                -1
            );
            const latestIndex =
                searchedMessageIndex === -1
                    ? messages.length - 1
                    : searchedMessageIndex;
            return {
                message: messages[latestIndex].value,
                chatId,
                phone: phone.value,
                contactId,
                name: name.value,
                searchedMessageIndex,
            };
        }
    );
    return latestMessages;
};

const performNamePhoneSearch = async (
    value,
    mode,
    showUnread,
    showAssistanceRequired,
    selectedTags
) => {
    const searchAttribute = mode === "1" ? ["name"] : ["phone"];
    let filterQuery = "";
    let delim = "";
    for (const tag in selectedTags) {
        filterQuery += delim;
        // tagQuery += 'tags:' + '"' + tags[tag] + '"';
        filterQuery += `tags:"${selectedTags[tag]}"`;
        delim = " OR ";
    }
    if (showUnread) {
        if (filterQuery !== "") filterQuery += " AND ";
        filterQuery += `unread:true`;
    }

    if (showAssistanceRequired) {
        if (filterQuery !== "") filterQuery += " AND ";
        filterQuery += `assistanceRequired:true`;
    }
    const { hits } = await algoliaIndex.search(value, {
        restrictSearchableAttributes: searchAttribute,
        attributesToRetrieve: [
            "objectID",
            "name",
            "contactID",
            "timestamp",
            "phone",
            "assistanceRequired",
            "tags",
        ],
        filters: filterQuery,
        hitsPerPage: 100,
        highlightPreTag: "<mark>",
        highlightPostTag: "</mark>",
    });
    const results = hits
        .filter(({ contactID }) => contactID !== undefined)
        .map(
            ({
                contactID: contactId,
                objectID: id,
                timestamp: ts,
                assistanceRequired: callback,
                tags,
            }) => {
				if (callback === undefined) {
					callback = false;
				}
                const tagsString = tags.join("~");
                const key = "all" + (id || contactId);
                return {
                    id,
                    key,
                    ts,
                    contactId,
                    callback,
                    tags: tagsString,
                };
            }
        );
	return results;
};

const MemoizedChatWindow = React.memo(({ data }) => <ChatWindowV2 {...data} />);

function WindowList({
    dbPath,
    menuId,
    filterType,
    searchQuery,
    selectedTags,
    searchMode,
    showUnread,
    showAssistanceRequired,
}) {
    const [chatArray, setChatArray] = useState([]);
    const [searchedList, setSearchedList] = useState([]);

    const { adminId } = useContext(myContext);
    // const filters = useMemo(
    //     () => ({
    //         unread: ({ unread }) => unread && unread > 0,
    //         assistanceRequired: ({ callback }) => callback,
    //         tags: ({ tags }) =>
    //             tags.split("~").some((tag) => selectedTags.includes(tag)),
    //     }),
    //     [selectedTags]
    // );

    useEffect(() => {
        const addChatsListener = (path) => {
            db.ref(path)
                .orderByChild("neg_ts")
                .on("value", (snapshot) => {
                    const chatArray = Object.entries(snapshot.val());
                    const chatArrayReshaped = chatArray
                        .filter(([, { chatId }]) => chatId)
                        .map(
                            ([
                                contactId,
                                { chatId, timestamp, callback, tags },
                            ]) => ({
                                id: chatId,
                                key: menuId + (chatId || contactId),
                                ts: timestamp,
                                contactId: contactId,
                                callback,
                                tags: tags ?? "",
                            })
                        );
                    setChatArray(
                        chatArrayReshaped.sort(
                            ({ ts }, { ts: tsb }) => tsb - ts
                        )
                    );
                });
        };
        const path = dbPath + (menuId === "agentWise" ? adminUID : adminId);
        addChatsListener(path);
    }, [adminId, dbPath, menuId]);

    useEffect(() => {
        if (searchMode === "2") {
            if (searchQuery !== "") {
                performAlgoliaSearch(searchQuery).then((latestMessages) => {
                    setSearchedList(latestMessages);
                });
            } else {
                setSearchedList([]);
            }
        } else {
            performNamePhoneSearch(
                searchQuery,
                searchMode,
                showUnread,
                showAssistanceRequired,
                selectedTags
            ).then((results) => {
				setChatArray(results);
			});
        }
    }, [
        searchQuery,
        searchMode,
        selectedTags,
        showUnread,
        showAssistanceRequired,
    ]);

    if (searchMode === "2") {
        return <SearchResults searchedList={searchedList} />;
    }
    return (
        <Virtuoso
            style={{ height: 892 - 64 }}
            // data={filterType === "" ? chatArray : chatArray.filter(filters[filterType])}
            data={chatArray}
            itemContent={(index, { id, key, ts, contactId }) => (
                <MemoizedChatWindow data={{ id, key, ts, contactId }} />
            )}
        />
    );
}

export default WindowList;
