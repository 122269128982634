import React from "react";
import { Card, Badge, Row, Typography, Col, Tag, Space, Avatar, Button, Dropdown } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";

import dayjs from "dayjs";
import { GoKebabVertical } from "react-icons/go";

function hashCode(str) {
	// java String#hashCode
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
}

function getHexFromHash(i) {
	const c = (i & 0x00ffffff).toString(16).toUpperCase();
	return "#" + c.padStart(6, "0");
}

const AllTags = ({ tags, onCloseTags }) => {
	return tags
		.split("~")
		.filter((tag) => tag !== "") // filter out empty string
		.map((element) => {
			const hexColor = getHexFromHash(hashCode(element));
			return (
				<Tag
					key={element}
					// closable
					// onClose={() => onCloseTags(element)}
					style={{ backgroundColor: hexColor + "88", color: "aliceblue" }}>
					{element}
				</Tag>
			);
		});
};

function ChatWindowCard({ assistanceRequired, chatInfo, count, attendedBy, tags, onCloseTags, menu }) {
	const currentDate = dayjs().format("DD/MM/YYYY");

	return (
		<Card
			title={
				<Row align="middle" justify="space-between">
					<Space>
						<Avatar style={{ backgroundColor: getHexFromHash(hashCode(chatInfo.contactName)) }}>
							{chatInfo.contactName[0]}
						</Avatar>
						<strong style={{ color: "#393E46" }}>{chatInfo.contactName}</strong>
					</Space>
				</Row>
			}
			className={`chatlist-card ${assistanceRequired ? "assistance-required" : ""}`}
			extra={
				<Row align="middle">
					{tags.includes("Important") && (
						<Col>
							<ExclamationCircleOutlined style={{ fontSize: "1.3em", marginRight: "0.5em" }} />
						</Col>
					)}
					<Col span={3}>
						<Dropdown overlay={menu} trigger="click">
							<Button type="ghost" icon={<GoKebabVertical />}></Button>
						</Dropdown>
					</Col>
				</Row>
			}>
			{tags !== "" && <AllTags tags={tags} onCloseTags={onCloseTags} />}

			<Row style={{ marginTop: tags !== "" && "1em" }}>
				<Col span={17}>
					<Typography.Paragraph ellipsis={{ rows: 2 }} style={{ color: "#222831", opacity: 0.7 }}>
						{chatInfo.lastMessage}
					</Typography.Paragraph>
				</Col>
				<Col span={7} style={{ textAlign: "right" }}>
					<Typography.Text type="secondary" strong>
						{currentDate === chatInfo.lastMessageDate ? chatInfo.lastMessageTime : chatInfo.lastMessageDate}
					</Typography.Text>
					<br />
					<Badge count={count} />
				</Col>
			</Row>

			{attendedBy !== "none" && (
				<Card.Meta
					title={
						<span style={{ color: "#393E46" }}>
							Attended By: <strong>{attendedBy}</strong>
						</span>
					}
				/>
			)}
		</Card>
	);
}

export default ChatWindowCard;
