import React from "react";

import { Layout, Row, Col, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { IoArrowBackOutline } from "react-icons/all";
import { Link, useLocation } from "react-router-dom";
const { Header } = Layout;

const TopBar = ({ togglePageMenuVisibility }) => {
	let { pathname } = useLocation();
	return (
		<Header className="header contact-header">
			<Row align="middle">
				<Col span={4}>
					<Button type="link" size="large" shape="circle">
						<Link to="/dashboard/conversations">
							<IoArrowBackOutline style={{ color: "aliceblue", fontSize: "2em" }} />
						</Link>
					</Button>
				</Col>
				<Col span={12} style={{ fontSize: "large", color: "#D4D4D4" }}>
					{pathname.split("/")[2].toUpperCase()}
				</Col>
				<Col span={3} push={5} style={{ textAlign: "right" }}>
					<Button
						type="ghost"
						shape="circle"
						size="large"
						icon={<MenuOutlined style={{ fontSize: "1em", color: "aliceblue" }} />}
						onClick={togglePageMenuVisibility}
					/>
				</Col>
			</Row>
		</Header>
	);
};

export default TopBar;
