import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Col, Row, Spin } from "antd";
import { db } from "../../config/_firebase.js";

import Graph from "./Graph";

import "./analytics.css";

const setUpGraphData = (messageData) => {
	const graphData = {
		labels: messageData.dailyX,
		datasets: [
			{
				label: "Admin Messages",
				data: messageData.adminMessages,
				fill: false,
				backgroundColor: "rgb(255, 99, 132)",
				borderColor: "rgb(255, 99, 132)",
			},
			{
				label: "Auto Reply",
				data: messageData.autoReplies,
				fill: false,
				backgroundColor: "rgb(75, 192, 192)",
				borderColor: "rgb(75, 192, 192)",
			},
			{
				label: "Received Messages",
				data: messageData.received,
				fill: false,
				backgroundColor: "rgb(99, 255, 132)",
				borderColor: "rgb(99, 255, 132)",
			},
			{
				label: "Sent Messages",
				data: messageData.sent,
				fill: false,
				backgroundColor: "rgb(175, 99, 255)",
				borderColor: "rgb(175, 99, 255)",
			},
			{
				label: "Session Messages",
				data: messageData.sessionMessages,
				fill: false,
				backgroundColor: "rgb(75, 99, 132)",
				borderColor: "rgb(75, 99, 132)",
			},
			{
				label: "Template Messages",
				data: messageData.templateMessages,
				fill: false,
				backgroundColor: "rgb(255, 193, 22)",
				borderColor: "rgb(255, 193, 22)",
			},
		],
	};
	return graphData;
};

function separateDataAndCount(analyticsData) {
	return analyticsData.reduce(
		({ messages, count }, { date, data }) => {
			return {
				messages: {
					dailyX: [...messages.dailyX, date],
					adminMessages: [...messages.adminMessages, parseInt(data.admin_messages)],
					autoReplies: [...messages.autoReplies, parseInt(data.auto_reply)],
					received: [...messages.received, parseInt(data.received)],
					sent: [...messages.sent, parseInt(data.sent)],
					sessionMessages: [...messages.sessionMessages, parseInt(data.session_messages)],
					templateMessages: [...messages.templateMessages, parseInt(data.template_messages)],
				},
				count: {
					adminMessages: count.adminMessages + parseInt(data.admin_messages),
					autoReplies: count.autoReplies + parseInt(data.auto_reply),
					received: count.received + parseInt(data.received),
					sent: count.sent + parseInt(data.sent),
					templateMessages: count.templateMessages + parseInt(data.template_messages),
				},
			};
		},
		{
			messages: {
				adminMessages: [],
				autoReplies: [],
				received: [],
				sent: [],
				sessionMessages: [],
				templateMessages: [],
				dailyX: [],
			},
			count: {
				received: 0,
				sent: 0,
				autoReplies: 0,
				adminMessages: 0,
				templateMessages: 0,
			},
		}
	);
}

export default function AnalyticsPage(props) {
	const [graphData, setGraphData] = useState({});
	const [countData, setCountData] = useState({});

	useEffect(() => {
		db.ref(`chatAnalytics/${props.adminId}`).once("value", (snapshot) => {
			const obj = snapshot.val();
			const analyticsData = Object.keys(obj)
				.map((dateKey) => ({
					date: dateKey,
					ts: +dayjs(dateKey.split("-").reverse().join("-")),
					data: obj[dateKey],
				}))
				.sort(({ ts: ts1 }, { ts: ts2 }) => ts1 - ts2);
			arrangeGraphData(analyticsData);
		});
	}, [props.adminId]);

	const arrangeGraphData = (analyticsData) => {
		const { messages, count } = separateDataAndCount(analyticsData);

		const _graphData = setUpGraphData(messages);
		setGraphData(_graphData);
		setCountData({
			...count,
			sessionMessages: count.received + count.sent - count.templateMessages,
		});
	};

	return countData.received ? (
		<div style={{ overflowY: "scroll", height: "calc(100vh - 64px)", padding: "2em" }}>
			<Row>
				<Col span={12} style={{ textAlign: "center" }}>
					<span className="analytics-numbers">{countData.received}</span>
					<div>Total Messages Received</div>
				</Col>
				<Col span={12} style={{ textAlign: "center" }}>
					<span className="analytics-numbers">{countData.sessionMessages}</span>
					<div>Total Session Messages</div>
				</Col>
				<Col span={12} style={{ textAlign: "center" }}>
					<span className="analytics-numbers">{countData.sent}</span>
					<div>Total Messages Sent</div>
				</Col>
				<Col span={12} style={{ textAlign: "center" }}>
					<span className="analytics-numbers">{countData.autoReplies}</span>
					<div>Total Auto Replies</div>
				</Col>
			</Row>

			<br />

			<span style={{ fontSize: "larger", fontWeight: 600 }}>Message Statistics</span>

			<div style={{ marginTop: "1em", height: "50vh" }}>
				<Graph data={graphData} />
			</div>
		</div>
	) : (
		<div className="loader">
			<Spin />
		</div>
	);
}
