import React, { useState, useEffect } from "react";
import { db } from "../../config/_firebase.js";
import dayjs from "dayjs";

import { List, Checkbox, Button } from "antd";
import { BiSortAZ, BiSortZA, BiTime, FaSort } from "react-icons/all";

import "./contacts.css";
import UtilityBar from "./UtilityBar.jsx";
import { FaPhoneAlt } from "react-icons/fa";

async function getContact(id) {
	const currContactSnapshot = db.ref(`Users/${id}`);
	const snapshot = await currContactSnapshot.once("value");

	const contact = snapshot.exists() ? snapshot.val() : {};
	return contact;
}

const getContactKeysAndTimestamps = (adminId) => {
	return db
		.ref(`v2_userChats/${adminId}`)
		.once("value")
		.then((snapshot) => {
			const snapshotArray = Object.entries(snapshot.val());
			const contactKeysAndTs = snapshotArray.map((obj) => [obj[0], obj[1].timestamp]);
			return contactKeysAndTs;
		});
};

const getAllContactsList = async (adminId) => {
	const contactKeysAndTimestampPairs = await getContactKeysAndTimestamps(adminId);
	const promises = contactKeysAndTimestampPairs.map((pair) => getContact(pair[0]));
	const results = await Promise.all(promises); // this step takes a lot of time
	const timestampObj = Object.fromEntries(contactKeysAndTimestampPairs);
	const contacts = results
		.map(({ id, userDisplayName, userPhone }) => {
			return {
				id,
				userDisplayName,
				userPhone,
				timestamp: timestampObj[id] * 1000,
			};
		})
		.filter((contact) => contact.id && contact.timestamp);
	return contacts;
};

const sortMethods = [
	{
		key: "no-sort",
		method: () => {},
		buttonContent: (
			<>
				Sort By <FaSort style={{ marginLeft: "0.6em" }} />
			</>
		),
	},
	{
		key: "name-asc",
		method: (user1, user2) => user1.userDisplayName.localeCompare(user2.userDisplayName),
		buttonContent: (
			<>
				Name (A-Z) <BiSortAZ style={{ marginLeft: "0.6em" }} />
			</>
		),
	},
	{
		key: "name-desc",
		method: (user1, user2) => user2.userDisplayName.localeCompare(user1.userDisplayName),
		buttonContent: (
			<>
				Name (Z-A) <BiSortZA style={{ marginLeft: "0.6em" }} />
			</>
		),
	},
	{
		key: "ts-asc",
		method: (user1, user2) => user2.timestamp - user1.timestamp,
		buttonContent: (
			<>
				Recent <BiTime style={{ marginLeft: "0.6em" }} />
			</>
		),
	},
	{
		key: "ts-desc",
		method: (user1, user2) => user1.timestamp - user2.timestamp,
		buttonContent: (
			<>
				Old <BiTime style={{ marginLeft: "0.6em" }} />
			</>
		),
	},
];

export default function Contacts({ adminId }) {
	const [rows, setRows] = useState([]);
	const [selectedContacts, setSelectedContacts] = useState([]);
	const [sortMethodIndex, setSortMethodIndex] = useState(0);

	useEffect(() => {
		getAllContactsList(adminId).then((contacts) => {
			setRows(contacts);
		});
	}, [adminId]);

	const handleSelectionChange = (isSelected, selectedContact) => {
		const _selectedRows = isSelected
			? [...selectedContacts, selectedContact]
			: selectedContacts.filter(({ id }) => id !== selectedContact.id);
		setSelectedContacts(_selectedRows);
	};

	const changeSortMethod = () => {
		setSortMethodIndex((currMethodIndex) => (currMethodIndex + 1) % sortMethods.length);
	};

	const SelectAllCheckBox = (
		<Checkbox
			indeterminate={selectedContacts.length > 0 && selectedContacts.length < rows.length}
			onChange={(e) => {
				if (e.target.checked) {
					setSelectedContacts(rows);
				} else {
					setSelectedContacts([]);
				}
			}}>
			Select All
		</Checkbox>
	);
	const SortButton = (
		<Button size="large" onClick={changeSortMethod}>
			{sortMethods[sortMethodIndex].buttonContent}
		</Button>
	);

	return (
		<>
			<UtilityBar
				selectedContacts={selectedContacts}
				SelectAllCheckBox={SelectAllCheckBox}
			/>
			<div
				style={{
					minHeight: "100vh",
					width: "100%",
					padding: "1.5em",
					backgroundColor: "#F5F5F5",
				}}>
				<List
					loading={rows.length === 0}
					pagination={{
						position: "top",
						defaultPageSize: 15,
						showTotal: (total, range) => SortButton,
						showLessItems: true,
						size: window.innerWidth < 400 ? "small" : "default",
					}}
					itemLayout="horizontal"
					dataSource={
						sortMethods[sortMethodIndex].key === "no-sort"
							? rows
							: [...rows].sort(sortMethods[sortMethodIndex].method)
					}
					renderItem={(item) => (
						<List.Item>
							<Checkbox
								className="contact-checkbox"
								onChange={(e) => handleSelectionChange(e.target.checked, item)}
								checked={selectedContacts.includes(item)}
							/>
							<List.Item.Meta
								title={
									<span style={{ color: "#393E46", fontWeight: 700 }}> {item.userDisplayName} </span>
								}
								description={
									<div
										style={{
											width: "100%",
											display: "flex",
											justifyContent: "space-between",
										}}>
										<span style={{ color: "#A7A7A7" }}>
											<FaPhoneAlt /> {item.userPhone}
										</span>
										<span style={{ color: "#364F6B", fontWeight: 600 }}>
											{dayjs(item.timestamp).format("DD/MM/YYYY HH:mm A")}
										</span>
									</div>
								}
							/>
						</List.Item>
					)}
				/>
			</div>
		</>
	);
}
