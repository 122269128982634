import React, { useEffect, useState } from "react";
import axios from "axios";
import { parse, unparse } from "papaparse";
import { Upload, Button, Typography, Space, Modal, Row, Col, Empty, Spin } from "antd";
import { FileAddOutlined, SendOutlined } from "@ant-design/icons";

import TemplateCard from "./TemplateCard";
import "./templates.css";

import { db } from "../../config/_firebase";
import downloadCSV from "../Common/downloadCSV";

const adminNum = localStorage.getItem("phoneNum");
const FETCHING_TEMPLATES = "FETCHING_TEMPLATES";
const SENDING_MESSAGES = "SENDING_MESSAGES";

const downloadExampleCSV = (columnStr) => {
	const params = columnStr !== "" ? columnStr.split("$") : [];
	const fields = [...params, "phone"];
	const data = Array(3)
		.fill(0)
		.map(() => fields.map(() => Math.random()));
	const csv = unparse({ fields, data });
	downloadCSV(csv, "template-example.csv");
};

export default function TemplatePage(props) {
	const [csvData, setCsvData] = useState([]);
	const [fileList, setFileList] = useState([]);
	const [allTemplates, setTemplates] = useState([]);
	const [selectedTemplateData, setSelectedTemplateData] = useState({});
	const [loading, setLoading] = useState(FETCHING_TEMPLATES);

	useEffect(() => {
		db.ref(`templateMessages/${adminNum}`).once("value", (snapshot) => {
			if (snapshot.exists()) {
				const templateList = snapshot.val().filter((entry) => entry);
				setTemplates(templateList);
			}
			setLoading("");
		});
	}, []);

	const sendMessage = async () => {
		if (csvData.length === 0) return;
		if (Object.keys(selectedTemplateData).length === 0) return;

		setLoading(SENDING_MESSAGES);
		const header_ = {
			headers: {
				"app-name": "prescribe-chatbot-admin",
			},
		};

		const request = {
			data: csvData,
			message: selectedTemplateData.boilerPlate,
			params: selectedTemplateData.params,
			sender: adminNum,
			template_id: selectedTemplateData.template_id,
		};
		try {
			const res = await axios.post(
				"https://prescribe-message-backend.herokuapp.com/send-template-message-excel",
				request,
				header_
			);
			if (res.data && res.data.success) {
				Modal.success({
					title: "Success!",
					content: "Your messages have been queued and will reach your selected contacts.",
				});
			}
		} catch (error) {
			Modal.error({
				title: "Oops!",
				content: "An error occured:" + error.message,
			});
			console.log(error);
		} finally {
			setLoading("");
		}
	};

	const uploadProps = {
		onRemove: (file) => {
			setFileList([]);
		},
		beforeUpload: (file) => {
			setFileList((prevFileList) => [file]);
			return false;
		},
		onChange: (info) => {
			const { file } = info;

			if (file.status !== "removed") {
				parse(file, {
					header: true,
					transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
					dynamicTyping: true,
					skipEmptyLines: true,
					complete: ({ data }) => {
						setCsvData(data);
					},
				});
			} else {
				setCsvData({});
			}
		},

		fileList,
		accept: ".csv",
		disabled: !selectedTemplateData.template_id,
	};
	return (
		<div style={{ padding: "1em" }}>
			<div style={{ padding: "1em" }}>
				<Typography.Title level={3}>Select a template to share as message</Typography.Title>
				<Space align="start" size="large">
					<Upload {...uploadProps}>
						<Button size="large" type="primary" disabled={!selectedTemplateData.template_id}>
							Select File <FileAddOutlined />
						</Button>
					</Upload>
					<Button
						size="large"
						type="primary"
						loading={loading === SENDING_MESSAGES}
						disabled={!selectedTemplateData.template_id || fileList.length === 0}
						onClick={sendMessage}>
						Send Bulk Message <SendOutlined />
					</Button>
				</Space>
			</div>
			<div style={{ padding: "1em" }}>
				<Typography.Title level={3}>Templates</Typography.Title>
				<Row gutter={24}>
					{allTemplates.length > 0 ? (
						allTemplates.map((data) => (
							<Col xs={24} md={18} lg={12} key={data.template_id}>
								<TemplateCard
									data={data}
									isSelected={data.template_id === selectedTemplateData.template_id}
									setSelectedTemplateData={setSelectedTemplateData}
									downloadExampleCSV={() => downloadExampleCSV(data.params)}
								/>
							</Col>
						))
					) : loading === FETCHING_TEMPLATES ? (
						<Spin size="large" />
					) : (
						<Empty description="No Templates in your account" />
					)}
				</Row>
			</div>
		</div>
	);
}
