import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { Spin } from "antd";
import axios from "axios";

const authenticateRefreshToken = async (token) => {
	const request = {
		token
	}
	const header_ = {
		headers: {
			"app-name": "prescribe-chatbot-admin",
		},
	};
	const result = await axios.post("https://prescribe-message-backend.herokuapp.com/verify-refresh-token", request, header_);
	if (result.data) {
		const response = await result.data;
		return response.status;
	} else {
		return false;
	}
}



const PrivateRoute = ({ component: Cmp, ...rest }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [loading, setIsLoading] = useState(true);

	useEffect(() => {
		let isMounted = true;
		const checkLoginInStatus = async () => {
			if (!localStorage.getItem("token")) {
				if (isMounted) {
					setIsAuthenticated(false);
					setIsLoading(false);
				}
			} else {
				const result = await authenticateRefreshToken(localStorage.getItem("token"));
				if (!result) {
					localStorage.clear();
				}
				if (isMounted) {
					setIsAuthenticated(result);
					setIsLoading(false);
				}
			}
		}
		checkLoginInStatus();
		return () => { isMounted = false };
	}, [isAuthenticated])

	return (
		<Route
			{...rest}
			render={(props) => 
				isAuthenticated ? (
					<Cmp {...props} />
				) : loading ? (
					<div className="loader">
						<Spin size="large" />
					</div>
				) : (
					<Redirect to="/login" />
				)
			}
		/>
	)
};
const PublicRoute = ({ component: Cmp, ...rest }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [loading, setIsLoading] = useState(true);

	useEffect(() => {
		let isMounted = true;
		const checkLoginInStatus = async () => {
			if (!localStorage.getItem("token")) {
				if (isMounted) {
					setIsAuthenticated(false);
					setIsLoading(false);
				}
			} else {
				const result = await authenticateRefreshToken(localStorage.getItem("token"));
				if (!result) {
					localStorage.clear();
				}
				if (isMounted) {
					setIsAuthenticated(result);
					setIsLoading(false);
				}
			}
		}
		checkLoginInStatus();
		return () => { isMounted = false };
	}, [isAuthenticated])

	return (
		<Route
			{...rest}
			render={(props) => 
				isAuthenticated ? (
					<Redirect to="/dashboard/conversations" />
				) : loading ? (
					<div className="loader">
						<Spin size="large" />
					</div>
				) : (
					<Cmp {...props} />
				)
			}
		/>
	)
};

export { PrivateRoute, PublicRoute };
