import React, { useState } from "react";

import { Layout, Row, Col, Button, Input, Select } from "antd";
import { FilterOutlined, SearchOutlined, MenuOutlined } from "@ant-design/icons";
import { IoArrowBackOutline } from "react-icons/all";

const { Header } = Layout;

const TopBar = ({ togglePageMenuVisibility, toggleFilterMenuVisibility, onSearch, changeSearchMode }) => {
	const [isSearching, setIsSearching] = useState(false);

	return (
		<Header className="header">
			<Row align="middle">
				{isSearching ? (
					<Col span={22}>
						<Input.Search
							onSearch={onSearch}
							bordered={false}
							autoFocus
							width={ 200 }
							allowClear
							prefix={
								<>
									<Button
										className="icon-button"
										onClick={() => {
											setIsSearching(false);
											onSearch("");
										}}>
										<IoArrowBackOutline color="aliceblue" size={30} />
									</Button>
									<Select
										dropdownMatchSelectWidth={false}
										optionLabelProp="title"
										defaultValue="1"
										onChange={changeSearchMode}
										size="small"
										bordered={false}>
										<Select.Option title="N" value="1">
											Name
										</Select.Option>
										<Select.Option title="K" value="2">
											Keyword
										</Select.Option>
										<Select.Option title="P" value="3">
											Phone
										</Select.Option>
									</Select>
								</>
							}
						/>
					</Col>
				) : (
					<>
						<Col span={12}>
							<span className="hospital-name-heading">{localStorage.getItem("adminName")}</span>
						</Col>
						<Col span={4}>
							<Button
								type="ghost"
								shape="circle"
								size="large"
								icon={<SearchOutlined style={{ fontSize: "1em", color: "aliceblue" }} />}
								onClick={() => setIsSearching(true)}
							/>
						</Col>
						<Col span={4}>
							<Button
								type="ghost"
								shape="circle"
								size="large"
								icon={<FilterOutlined style={{ fontSize: "1em", color: "aliceblue" }} />}
								onClick={toggleFilterMenuVisibility}
							/>
						</Col>
						<Col span={4}>
							<Button
								type="ghost"
								shape="circle"
								size="large"
								icon={<MenuOutlined style={{ fontSize: "1em", color: "aliceblue" }} />}
								onClick={togglePageMenuVisibility}
							/>
						</Col>
					</>
				)}
			</Row>
		</Header>
	);
};

export default TopBar;
