import React, { useState } from "react";
import { db } from "../../config/_firebase";
import { Button, List, Modal } from "antd";
import { ASSIGNED_TO } from "../../config/amplitudeEvents";
import { sendAmplitudeData } from "../../config/amplitudeFunctions";
import algoliaIndex from "../../config/_algolia";

const dateToTimestamp = (date, time) => {
	var dateArr = date.split("-");
	var timeArr = time.split(":");
	var date_ = new Date(
		parseInt(dateArr[2]),
		parseInt(dateArr[1]) - 1,
		parseInt(dateArr[0]),
		parseInt(timeArr[0]),
		parseInt(timeArr[1])
	);
	return date_.getTime() / 1000;
};

export default function AssignToModal(props) {
	// const [attendantId, setAttendantId] = useState("");
	const [isOpen, setIsOpen] = useState(false);

	const toggleModal = () => {
		setIsOpen((prevStateOpen) => !prevStateOpen);
	};

	// useEffect(() => {
	// 	const ref = db.ref(`Users/${props.contact.id}`);
	// 	ref.on("value", (snapshot) => {
	// 		const { attendedById } = snapshot.val();
	// 		setAttendantId(attendedById);
	// 	});
	// 	return () => {
	// 		ref.off();
	// 	};
	// }, [props.contact.id]);

	const assignUserToAdmin = (adminName, adminID_) => {
		const ts_ = dateToTimestamp(props.lastMessageDate, props.lastMessageTime);
		const obj = props.chatObj;
		obj['attendedById'] = adminID_;
		obj['attendedBy'] = adminName;
		obj['auto_reply'] = 'false';
		obj['auto_reply_ts'] = ts_;
		db.ref(`v2_agentWiseChats/${props.attendedById}/${props.contact.id}`).set(null);
		db.ref(`v2_agentWiseChats/${adminID_}/${props.contact.id}`).set(obj);
		db.ref(`v2_assignedChats/${props.adminID}/${props.contact.id}`).set(obj);
		db.ref(`v2_userChats/${props.adminID}/${props.contact.id}`).set(obj);
		algoliaIndex.partialUpdateObject({
			'objectID': props.id,
			'attendedById': adminID_
		});
	};
	return (
		<>
			<Button onClick={toggleModal}>Assign To</Button>
			<Modal
				visible={isOpen}
				title={<span style={{ fontWeight: 600 }}>Assign To</span>}
				closable={false}
				onOk={toggleModal}
				onCancel={toggleModal}>
				<List
					dataSource={props.adminList}
					renderItem={(admin) => (
						<List.Item>
							<Button
								type="link"
								key={admin.uid}
								onClick={() => {
									sendAmplitudeData(ASSIGNED_TO);
									assignUserToAdmin(admin.name, admin.uid);
								}}>
								{admin.name}
							</Button>
						</List.Item>
					)}
				/>
			</Modal>
		</>
	);
}
