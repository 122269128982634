import { Divider } from "antd";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Virtuoso } from "react-virtuoso";
import { myContext } from "./myContext";

const SearchResults = ({ searchedList }) => {
	const hist = useHistory();
	const { adminList } = useContext(myContext);

	const goToMessage = ({ chatId, contactId, searchedMessageIndex }) => {
		hist.push(`/dashboard/conversations/conversation?chatId=${chatId}&contactId=${contactId}`, {
			adminList,
			searchedMessageIndex,
		});
	};
	return (
		<Virtuoso
			style={{ height: window.innerHeight - 64 }}
			data={searchedList}
			itemContent={(index, { name, phone, message, ...rest }) => {
				const [pre, searchpart, restWord, trail] = message.split(/<mark>(.*)<\/mark>(\w+|\s|\W|$)/g);
				return (
					<div style={{ padding: "0 1.5em" }} role="link" onClick={() => goToMessage(rest)}>
						<Divider />
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
								marginBottom: "0.5em",
							}}>
							<span
								style={{ color: "#393E46", fontWeight: 700 }}
								dangerouslySetInnerHTML={{ __html: name }}></span>
							<span
								style={{ color: "#364F6B", fontWeight: 600 }}
								dangerouslySetInnerHTML={{ __html: phone }}></span>
						</div>
						<div className="searched-message">
							{pre?.trim() !== "" && <span className="cutout-text">{pre}</span>}
							{searchpart && <mark className="match"> {searchpart + restWord}</mark>}
							{trail && trail?.trim() !== "" && <span className="cutout-text">...{trail}</span>}
						</div>
					</div>
				);
			}}
		/>
	);
};

export default SearchResults;
