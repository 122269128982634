import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { PrivateRoute, PublicRoute } from "./views/Common/SpecialRoutes";
import Login from "./views/Login/Login";
import Verification from "./views/Email-Verification/Verification";
import ResetPassword from "./views/Reset-Password/Reset-Password";
import Dashboard from "./views/Common/Dashboard";

import "antd/dist/antd.css";
import "./views/Common/App.css";

export default function App() {
	return (
		<Router>
			<Switch>
				<Route path="/" exact component={() => <Redirect to="/login" />} />
				<PublicRoute path="/login" exact component={Login} />
				<PublicRoute path="/verification" exact component={Verification} />
				<PublicRoute path="/reset" exact component={ResetPassword} />
				<PrivateRoute path="/dashboard/*" component={Dashboard} />
			</Switch>
		</Router>
	);
}
