import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal } from "antd";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import firebase from "firebase";

import App from "./App";
import "./views/Common/index.css";
import OfflinePage from "./views/Common/OfflinePage";
import { db } from "./config/_firebase.js";

const addTokenToDB = async (token) => {
	const phoneNum = localStorage.getItem("phoneNum");
	if (phoneNum) {
		const adminSnapshot = await db.ref('Users').orderByChild("userPhone").equalTo(phoneNum).once("value");

		if (adminSnapshot.exists()) {
			const adminId = Object.values(adminSnapshot.val())[0].id;
			const adminUid = localStorage.getItem("adminUID");
			db.ref(`pwa_token_list/${adminId}/${adminUid}/${token}`).set(true);
		}
	}
}

const PWAWrapper = () => {
	const [isOffline, setIsOffline] = useState(false);

	const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
	const [isInstalling, setIsInstalling] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const [waitingWorker, setWaitingWorker] = useState(null);
	const [appUpdateAvailable, setAppUpdateAvailable] = useState(false);

	useEffect(() => {
		const offlineToggler = ({ type }) => {
			setIsOffline(type === "offline");
		};
		window.addEventListener("offline", offlineToggler);
		window.addEventListener("online", offlineToggler);

		return () => {
			window.removeEventListener("offline", offlineToggler, true);
			window.removeEventListener("online", offlineToggler, true);
		};
	}, []);

	// Register service worker and notification permission
	useEffect(() => {
		serviceWorkerRegistration.register({
			onUpdate: (registration) => {
				setWaitingWorker(registration && registration.waiting);
				setAppUpdateAvailable(true);
			},
		});
	}, []);

	// Installation handling
	const handleInstallClick = (e) => {
		setIsInstalling(true);
		// Show the install prompt
		window.prescribeDeferredPrompt.prompt();
		// Wait for the user to respond to the prompt
		window.prescribeDeferredPrompt.userChoice.then((choiceResult) => {
			if (choiceResult.outcome === "accepted") {
				console.log("User accepted the install prompt");
			} else {
				console.log("User dismissed the install prompt");
			}
		});
	};
	useEffect(() => {
		const isNotWebAppiOS = !window.navigator.standalone === true;
		const isNotWebAppChrome = !window.matchMedia("(display-mode: standalone)").matches;

		if (isNotWebAppiOS && isNotWebAppChrome) {
			window.addEventListener("beforeinstallprompt", (e) => {
				// Prevent the mini-infobar from appearing on mobile
				e.preventDefault();
				// Stash the event as a global variable so it can be triggered later.
				window.prescribeDeferredPrompt = e;
				// Update UI notify the user they can install the PWA
				setIsInfoModalVisible(true);
				return e.preventDefault();
			});

			window.addEventListener("appinstalled", () => {
				setIsInfoModalVisible(false);
				Modal.destroyAll();
				Modal.success({
					title: "Your app is being installed.",
					content: "Check your homescreen/ app drawer for the installed app. You can now close this tab.",
				});
			});
		} else {
			window.addEventListener("beforeinstallprompt", (e) => {
				e.preventDefault();
			});
		}
	}, []);

	// Update handling
	useEffect(() => {
		const updateServiceWorker = () => {
			setIsUpdating(true);
			try {
				waitingWorker.postMessage({ type: "SKIP_WAITING" });
				setAppUpdateAvailable(false);
				Modal.destroyAll();
			} catch (error) {
				Modal.destroyAll();
				Modal.error({
					title: "Update couldn't be installed!",
					content: "Please restart the app.",
					okText: "Ok",
					maskClosable: false,
				});
			} finally {
				setIsUpdating(false);
				window.location.reload();
			}
		};
		if (appUpdateAvailable) {
			Modal.info({
				title: "App update available!",
				content: "Update the app to install bug fixes and improvements.",
				okText: "Update",
				maskClosable: false,
				onOk: updateServiceWorker,
				okButtonProps: { loading: isUpdating },
			});
		}
	}, [appUpdateAvailable, isUpdating, waitingWorker]);

	useEffect(() => {
		const phoneNum = localStorage.getItem("phoneNum");
		if (Notification.permission !== 'granted' && phoneNum) {
			const message = firebase.messaging();
			Notification.requestPermission().then((permission) => {
				if (permission === "granted") {
					message.getToken().then((data) => {
						localStorage.setItem("notificationToken", data);
						addTokenToDB(data);
					});
				}
			});
		}
	}, [])

	return (
		<>
			{isInfoModalVisible && (
				<Modal
					title="Install this app to homescreen"
					visible={isInfoModalVisible}
					onOk={handleInstallClick}
					okText={isInstalling ? "Installing" : "Install"}
					cancelText="Close"
					confirmLoading={isInstalling}
					maskClosable={false}
					closable={false}
					onCancel={() => setIsInfoModalVisible(false)}>
					<span>
						This website can be installed as an app to your device. Experience faster page loads by
						installing it.
					</span>
				</Modal>
			)}
			{isOffline && <OfflinePage />}
			<App />
		</>
	);
};

ReactDOM.render(<PWAWrapper />, document.getElementById("root"));
