import { AiFillCloseCircle, BiBot, BiUser, GoKebabVertical, MdArrowBack, RiSendPlane2Fill, FiPhoneCall } from "react-icons/all";
import { Header } from "antd/lib/layout/layout";
import { Switch, Avatar, Button, Form, Input, Spin, Row, Col, Dropdown, Menu } from "antd";
import { withRouter } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import React, { Component, createRef } from "react";

import AssignToModal from "./AssignToModal";
import AttachmentPopup from "./AttachmentPopup";
import { db } from "../../config/_firebase.js";
import Message from "./Message";
import TagManager from "./TagManager";
import QuickRepliesDropdown from "./QuickRepliesDropdown";

import "./conversation.css";
import { sendAmplitudeData } from "../../config/amplitudeFunctions";
import {
	AUTO_CHANGED,
	MESSAGE_RECEIVED,
	MESSAGE_SENT,
	QUICK_REPLY_SELECTED,
	REPLIED_TO_MESSAGE,
} from "../../config/amplitudeEvents";
import { unparse as parseToCSV } from "papaparse";
import downloadCSV from "../Common/downloadCSV";
import algoliaIndex from "../../config/_algolia";

const adminUID = localStorage.getItem("adminUID");
const adminName = localStorage.getItem("adminName");
const adminNum = localStorage.getItem("phoneNum");
const carrier = localStorage.getItem("carrier") || "gupshup";
const adminDepartment = localStorage.getItem("adminDepartment");

class OpenConversation extends Component {
	constructor(props) {
		super(props);
		this.messagesEnd = createRef();
		this.messagesStart = createRef();
		this.formRef = createRef();
		this.searchedMessageRef = createRef();
		this.replyMessageHolder = "";
		this.mounted = true;
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChatExport = this.handleChatExport.bind(this);
		this.state = {
			messageList: [],
			lastMessageDate: "",
			lastMessageTime: "",
			contact: {},
			chatId: "default",
			contactSelected: false,
			checked: false,
			isSending: false,
			attendedById: "none",
			chips: [],
			replyingTo: "",
			admiDbID: 0,
			chatObj: {},
      		chatStatus: "closed"
		};
	}

	handleSubmit({ message }) {
		message = message ? message.trim() : "";

		if (message === "") {
			window.alert("Empty messages aren't allowed");
			return;
		}
		this.setState({ isSending: true });
		this.sendMessage(message);
		this.formRef.current.setFieldsValue({ message: "" });
	}

	componentDidMount() {
		// console.log("Open Converstation Props", this.props);
		this.getAdminChipTemplates(localStorage.getItem("adminUID"));
	}

	componentDidUpdate() {
		if (
			this.props.chatId !== "no-select" &&
			(!this.state.contactSelected || this.state.chatId !== this.props.chatId)
		) {
			this.getContact(this.props.contactId);
		}
	}

	componentWillUnmount() {
		db.ref(`chatMessages/${this.props.chatId}`).off();
		db.ref(`Users/${this.props.chatId}`).off();

		this.mounted = false;
	}

	getAdminChipTemplates = (uid) => {
		if (this.mounted) {
			db.ref("UserCredentials")
				.orderByChild("uid")
				.equalTo(uid)
				.once("value", (snapshot) => {
					const value = Object.values(snapshot.val())[0];
					this.setState({
						admiDbID: value.dbID,
						chips: value.chips.split("~"),
					});
				});
		}
	};

	getContact(id) {
		// var contact = {};

		db.ref(`Users/${id}`).once("value", (snapshot) => {
			var curr_contact = snapshot.val();
			// console.log(curr_contact);
			this.setState(
				{
					contact: curr_contact,
					contactSelected: true,
					chatId: this.props.chatId,
				},
				() => {
					this.addChatObjListener(id);
					this.addChatListener(this.props.chatId);
				}
			);
		});
	}

	addChatObjListener = (contactId) => {
		db
		  .ref(`v2_userChats/${this.props.adminId}/${contactId}`)
		  .on("value", (snapshot) => {
			if (this.state.contact.id === contactId) {
			  const snapshotVal = snapshot.val();
			  if (snapshotVal !== undefined) {
				let tagsString = snapshotVal.tags ? snapshotVal.tags.trim() : "";
				let status = "closed";
				if (tagsString.includes("open")) status = "open";
				if (tagsString.includes("ongoing")) status = "ongoing";
			  
				this.setState({
				  tags: tagsString,
				  checked: snapshotVal.auto_reply === "true",
				  attendedById: snapshotVal.attendedById,
				  chatObj: snapshotVal,
				  chatStatus: status
				});
			  }
			}
		  });
	  };

	getReplyMessage = (msg, fromMe) => {
		sendAmplitudeData(REPLIED_TO_MESSAGE, { length: msg.length });
		this.replyMessageHolder = msg;
		this.setState({ replyingTo: fromMe ? "Me" : this.state.contact.userDisplayName.split(" ")[0] });
	};

	scrollToBottom = () => {
		this.messagesEnd.scrollIntoView();
	};

	addMessageScrollListener = () => {
		const ref = db.ref(`chatMessages/${this.props.chatId}`);

		const addPreviousMessages = () => {
			ref.orderByKey()
				.endAt(this.state.messageList[0].messageKey)
				.limitToLast(20)
				.once("value", (snapshot) => {
					if (snapshot.exists()) {
						const currentChats = Object.entries(snapshot.val());

						const moreMessageList = currentChats.slice(0, currentChats.length - 1).map(([key, chat]) => ({
							curr_chat: chat,
							chatId: this.props.chatId,
							fromMe: chat.sentBy === this.props.adminId,
							messageKey: key,
						}));
						this.setState((prevState) => ({
							...prevState,
							messageList: [...moreMessageList, ...prevState.messageList],
						}));
					}
				});
		};
		const messageScrollObserver = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					// load messages on scroll
					if (this.props.searchedMessageIndex === -1) {
						addPreviousMessages();
					}
				}
			},
			{ threshold: [0] }
		);

		if (this.messagesStart.current) {
			messageScrollObserver.observe(this.messagesStart.current);
		}
	};

	addChatListener(chatId) {
		const ref = db.ref(`chatMessages/${this.props.chatId}`);
		const searchedMessageIndex = this.props.searchedMessageIndex !== -1 ? this.props.searchedMessageIndex : 10;

		ref.endAt()
			.limitToLast(searchedMessageIndex + 10)
			.once("value", (snapshot) => {
				const currentChats = Object.entries(snapshot.val());

				const messageList = currentChats.map(([key, chat]) => ({
					curr_chat: chat,
					chatId,
					fromMe: chat.sentBy === this.props.adminId,
					messageKey: key,
				}));
				this.setState(
					{
						messageList,
						lastMessageDate: messageList[messageList.length - 1].curr_chat.messageDate,
						lastMessageTime: messageList[messageList.length - 1].curr_chat.messageTime,
					},
					() => {
						if (this.props.searchedMessageIndex === -1) {
							this.scrollToBottom();
						}
						if (this.state.messageList.length >= 20) {
							this.addMessageScrollListener();
						}
						listenForNewMessages();
					}
				);
			});

		// listen for new messages
		const listenForNewMessages = () => {
			ref.limitToLast(1).on("child_added", (snapshot) => {
				const curr_chat = snapshot.val();
				const messageKey = snapshot.key;
				const fromMe = curr_chat.sentBy === this.props.adminId;

				if (this.state.chatId === chatId) {
					const newMessage = {
						curr_chat,
						chatId,
						fromMe,
						messageKey,
					};

					this.setState((prevState) => {
						if (prevState.messageList.every(({ messageKey }) => messageKey !== newMessage.messageKey)) {
							if (fromMe) {
								sendAmplitudeData(MESSAGE_SENT, {
									sender: adminNum,
									recipient: this.state.contact.userPhone,
									intent: curr_chat.intent,
									value: curr_chat.message,
								});
							} else {
								sendAmplitudeData(MESSAGE_RECEIVED, {
									sender: this.state.contact.userPhone,
									intent: curr_chat.intent,
									value: curr_chat.message,
								});
							}
							return {
								messageList: [...prevState.messageList, newMessage],
								lastMessageDate: curr_chat.messageDate,
								lastMessageTime: curr_chat.messageTime,
							};
						}
						return prevState;
					}, this.scrollToBottom);
				}
			});
		};
	}

	handleOnClick = () => {
		this.setState({ checked: !this.state.checked });
	};

	handleToggleChange = () => {
		// var autoReplyState;
		sendAmplitudeData(AUTO_CHANGED, {
		  action: this.state.checked ? "turn off" : "turn on",
		});
	
		var attendedById_ = "none";
		var obj = this.state.chatObj;
		if (this.state.checked) {
		  // autoReplyState = "false";
		  attendedById_ = adminUID;
		  var attendedByName = adminName;
		  if (adminDepartment) {
			attendedByName += " - " + adminDepartment;
		  }
		  obj['attendedById'] = adminUID;
		  obj['attendedBy'] = attendedByName;
		  obj['auto_reply'] = 'false';
		  obj['auto_reply_ts'] = Math.round(Date.now() / 1000).toString();
		  // firebase
		  //   .database()
		  //   .ref(`v2_agentWiseChats/${this.state.attendedById}/${this.state.contact.id}`)
		  //   .set(null);
		  db
			.ref(`v2_agentWiseChats/${adminUID}/${this.state.contact.id}`)
			.set(obj);
		  db
			.ref(`v2_assignedChats/${this.props.adminId}/${this.state.contact.id}`)
			.set(obj);
		  db
			.ref(`v2_userChats/${this.props.adminId}/${this.state.contact.id}`)
			.set(obj);
		  algoliaIndex.partialUpdateObject({
			'objectID': this.state.chatId,
			'attendedById': adminUID
		  });
		} else {
		  // console.log(this.state.contact.attendedById);
		  obj['auto_reply'] = 'true';
		  obj['attendedById'] = '';
		  obj['attendedBy'] = 'bot';
		  obj['auto_reply_ts'] = Math.round(Date.now() / 1000).toString();
		  db
			.ref(
			  `v2_agentWiseChats/${this.state.attendedById}/${this.state.contact.id}`
			)
			.set(null);
		  db
			.ref(`v2_assignedChats/${this.props.adminId}/${this.state.contact.id}`)
			.set(null);
		  db
			.ref(`v2_userChats/${this.props.adminId}/${this.state.contact.id}`)
			.set(obj);
		  algoliaIndex.partialUpdateObject({
			'objectID': this.state.chatId,
			'attendedById': '-'
		  });
		  // autoReplyState = "true";
		}
		this.setState({
		  checked: !this.state.checked,
		  attendedById: attendedById_,
		});
		// this.changeAutoReplyStatus(this.state.contact, autoReplyState);
	  };

	sendMessage(message_) {
		const { replyingTo } = this.state;

		const timeNow = dayjs();
		const messageSender = localStorage.getItem("adminName");

		const isReply = replyingTo !== "";
		const replyingToName = replyingTo === "Me" ? messageSender : "You";
		const finalMsg = isReply
			? `*${replyingToName}:* ${this.replyMessageHolder}\n\n*${messageSender}*: ${message_}`
			: message_;

		const agentId = this.state.contact.attendedById ?? "";

		const request = {
			sender: adminNum,
			receiver: this.state.contact.userPhone,
			type: "text",
			date: timeNow.format("DD-MM-YYY"),
			time: timeNow.format("HH:MM"),
			carrier,
			payload: {
				message: finalMsg,
				caption: "",
				admin_id: this.props.adminId,
				client_id: this.state.contact.id,
				chat_id: this.state.chatId,
				agent_id: agentId,
				client_name: this.state.contact.userDisplayName,
			},
		};

		const header_ = {
			headers: {
				"app-name": "prescribe-chatbot-admin",
			},
		};

		axios
			.post("https://prescribe-message-backend.herokuapp.com/admin-message-v2", request, header_)
			.then(() => {
				if (isReply) {
					this.replyMessageHolder = "";
					this.setState({ replyingTo: "" });
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				this.setState({ isSending: false });
			});
	}

	handleImage(src) {
		window.open(src);
	}
	
	addTemplateMessage = (message) => {
		const currentTemplateMessages = this.state.chips;
		if (!currentTemplateMessages.includes(message.trim())) {
			const newArr = [...currentTemplateMessages, message];

			this.setState({ chips: newArr });
			const tagString = newArr.join("~");
			const admiDbID = this.state.admiDbID.toString();
			db.ref("UserCredentials").child(admiDbID).update({ chips: tagString });
		} else {
			window.alert("This message is already present among the templates.");
		}
	};

	deleteTemplateMessage = (message, index) => {
		var previousChipState = this.state.chips;
		const indexer = previousChipState.indexOf(message);
		if (indexer > -1) {
			previousChipState.splice(indexer, 1);
		}
		this.setState({ chips: previousChipState });
		const tagString = previousChipState.join("~");
		const dbIdState = this.state.admiDbID;
		const dbIdStr = dbIdState.toString();
		db.ref(`UserCredentials`).child(dbIdStr).update({ chips: tagString });
	};

	handleReplyClose = () => {
		this.setState({ replyingTo: "" });
	};

	handleChatExport = () => {
		db.ref(`chatMessages/${this.props.chatId}`).once("value", (snapshot) => {
			const messageList = Object.values(snapshot.val());
			const { userPhone, userDisplayName } = this.state.contact;
			const messageListReshaped = messageList
				.filter(({ type }) => type === "text")
				.map((chat) => {
					const { messageDate, messageTime, type, read, sentBy, ...commonFields } = chat;
					const fromMe = sentBy === this.props.adminId;
					return {
						sender: fromMe ? adminName : userDisplayName,
						phone: fromMe ? adminNum : userPhone,
						...commonFields,
						timestamp: `${messageDate} ${messageTime}`,
					};
				});
			const fields = Object.keys(messageListReshaped[0]).map((field) => field[0].toUpperCase() + field.slice(1));
			const csvData = parseToCSV({ fields, data: messageListReshaped.map(Object.values) });
			downloadCSV(csvData, `Chat-${adminName}-${userPhone}.csv`);
		});
	};

	render() {
		const isLastMessageByUs = this.state.messageList[this.state.messageList.length - 1]?.fromMe;
		if (this.state.contactSelected) {
			return (
				<>
					<Header className="header" style={{ position: "fixed", width: "100%", zIndex: 100 }}>
						<Row gutter={12} align="middle">
							<Col>
								<Button className="icon-button" onClick={this.props.history.goBack}>
									<MdArrowBack color="#ffffff" size={30} />
								</Button>
							</Col>
							<Col>
								<Avatar children={this.state.contact.userDisplayName[0]} />
							</Col>
							<Col
								style={{
									color: "#D4D4D4",
									fontSize: "1.1em",
									fontWeight: 600,
								}}>
								{this.state.contact.userDisplayName.split(" ")[0]}
							</Col>
							<Col flex="auto"></Col>
							<Col span={4}>

							<CopyToClipboard text={this.state.contact.userPhone}>
							{ <a href={"tel:+" + this.state.contact.userPhone} >	
							 <Button >  <FiPhoneCall size={20} /></Button>
							 </a>}
							 </CopyToClipboard> 
							</Col>	
							<Col span={3}>
								<Switch
									checkedChildren={<BiBot size={20} />}
									unCheckedChildren={<BiUser size={20} />}
									checked={this.state.checked}
									onChange={this.handleToggleChange}
								/>
							</Col>
							
							<Col span={4}>
								<Dropdown
									overlay={
										<Menu>
											<Menu.Item>
												<TagManager
													contactID={this.props.contactId}
													adminID={this.props.adminId}
												/>
											</Menu.Item>
											<Menu.Item>
												<AssignToModal
													id={this.props.chatId}
													adminList={this.props.adminList}
													adminID={this.props.adminId}
													contact={this.state.contact}
													lastMessageDate={this.state.lastMessageDate}
													lastMessageTime={this.state.lastMessageTime}
													chatObj={this.state.chatObj}
												/>
											</Menu.Item>
											<Menu.Item>
												<Button onClick={this.handleChatExport}>Export Chat</Button>
											</Menu.Item>
											<Menu.Item>
											<CopyToClipboard text={this.state.contact.userPhone}>
												<Button >Copy this Number</Button>
											</CopyToClipboard>	
											</Menu.Item>
										</Menu>
									}
									trigger={["click"]}>
									<Button className="icon-button">
										<GoKebabVertical color="#fff" size={30} />
									</Button>
								</Dropdown>
							</Col>
						</Row>
					</Header>
					<div className="conv_content">
						<div style={{ float: "left", clear: "both" }} ref={this.messagesStart}></div>
						<div style={{ marginTop: "20px" }}>
							{this.state.messageList.map((props, i) => (
								<Message
									key={props.messageKey}
									{...props}
									ref={
										this.state.messageList.length - i - 1 === this.props.searchedMessageIndex
											? this.searchedMessageRef
											: "23"
									}
									getReplyMessage={this.getReplyMessage}
									isLastMessageByUs={isLastMessageByUs}
								/>
							))}
						</div>
						<div
							ref={(el) => {
								this.messagesEnd = el;
							}}></div>
					</div>
					<Form
						ref={this.formRef}
						onFinish={this.handleSubmit}
						style={{
							position: "fixed",
							bottom: 0,
							width: "100%",
							padding: "0.5em",
							background: "#EEEEEE",
						}}>
						{this.state.replyingTo !== "" && (
							<Row className="reply-card" justify="space-between" align="middle">
								<Col
									span={22}
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}>
									<strong> {this.state.replyingTo}</strong>: {this.replyMessageHolder}{" "}
								</Col>
								<Col span={2} style={{ textAlign: "center" }}>
									<Button
										onClick={this.handleReplyClose}
										className="icon-button"
										size="small"
										icon={<AiFillCloseCircle size={22} color="#aaa" />}
									/>
								</Col>
							</Row>
						)}

						<Row align="middle">
							<Col span={3}>
								<QuickRepliesDropdown
									isAutoOn={this.state.checked}
									templateMessages={this.state.chips}
									addTemplateMessage={this.addTemplateMessage}
									deleteTemplateMessage={this.deleteTemplateMessage}
									onChoose={(message) => {
										this.formRef.current.setFieldsValue({ message });
										sendAmplitudeData(QUICK_REPLY_SELECTED, {
											message,
											length: message.length,
										});
									}}
								/>
							</Col>
							<Col span={3}>
								<AttachmentPopup
									checkedstate={this.state.checked}
									adminNum={adminNum}
									contact={this.state.contact}
									adminId={this.props.adminId}
									chatId={this.state.chatId}
									agentId={this.state.contact.attendedById ?? ""}
								/>
							</Col>
							<Col flex="auto">
								<Form.Item name="message" disabled={this.state.checked} autoFocus={true}>
									<Input.TextArea
										disabled={this.state.checked}
										placeholder={this.state.checked ? "Auto is on" : "Enter Message..."}
									/>
								</Form.Item>
							</Col>
							<Col span={3}>
								<Form.Item>
									<Button className="icon-button" htmlType="submit" disabled={this.state.checked}>
										{this.state.isSending ? <Spin size={25} /> : <RiSendPlane2Fill size={22} />}
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</>
			);
		} else {
			return (
				<div className="loader">
					<Spin size="large" />;
				</div>
			);
		}
	}
}

export default withRouter(OpenConversation);
