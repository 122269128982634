import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";

import ChatList from "../ChatListPage/ChatList";
import Contacts from "../ContactsPage/Contacts";
import { db } from "../../config/_firebase.js";
import OpenConversation from "../Conversation/OpenConversation";
import PageMenu from "./PageMenu";
import TemplatePage from "../Templates/Templates";
import AnalyticsPage from "../Analytics/AnalyticsPage";
import TopBar from "./TopBar";

const adminPhoneNum = localStorage.getItem("phoneNum");

async function getAdminId() {
	const adminRef = db.ref("Users");
	const adminSnapshot = await adminRef.orderByChild("userPhone").equalTo(adminPhoneNum).once("value");

	if (adminSnapshot.exists()) {
		const adminId = Object.values(adminSnapshot.val())[0].id;
		return adminId;
	}
}

export default function Dashboard() {
	const [adminId, setAdminId] = useState("");
	const [isPageMenuVisible, setPageMenuVisibility] = useState(false);
	const isConversationPage = useRouteMatch("/dashboard/conversations");

	useEffect(() => {
		if (!adminId) {
			getAdminId().then((_adminId) => setAdminId(_adminId));
		}
	});

	const togglePageMenuVisibility = () => setPageMenuVisibility((vis) => !vis);

	if (!adminId) {
		return (
			<div className="loader">
				<Spin size="large" />
			</div>
		);
	} else {
		return (
			<>
				<PageMenu isVisible={isPageMenuVisible} toggleVisibility={togglePageMenuVisibility} adminId={adminId} />
				{!isConversationPage && <TopBar togglePageMenuVisibility={togglePageMenuVisibility} />}
				<Switch>
					<Route exact path="/dashboard/conversations">
						<ChatList adminId={adminId} togglePageMenuVisibility={togglePageMenuVisibility} />
					</Route>
					<Route exact path="/dashboard/contacts">
						<Contacts adminId={adminId} />
					</Route>
					<Route exact path="/dashboard/templates">
						<TemplatePage />
					</Route>
					<Route exact path="/dashboard/analytics">
						<AnalyticsPage adminId={adminId} />
					</Route>

					<Route
						path="/dashboard/conversations/conversation"
						component={({ location }) => {
							const { adminList, searchedMessageIndex = -1 } = location.state;
							const query = new window.URLSearchParams(location.search);
							const chatId = query.get("chatId");
							const contactId = query.get("contactId");

							const props = { adminList, adminId, chatId, contactId, searchedMessageIndex };

							return <OpenConversation {...props} />;
						}}
					/>
				</Switch>
			</>
		);
	}
}
