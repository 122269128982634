/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ClickImageModal from "./ClickImageModal";
import { Row, Col } from "antd";
import { useSwipeable } from "react-swipeable";
import anchorme from "anchorme";
import { RiCheckDoubleFill, RiCheckFill, RiTimeLine } from "react-icons/ri";

import dayjs from "dayjs";
import { db } from "../../config/_firebase";

const statusIcons = {
	enqueued: <RiTimeLine color="gray" size={18} />,
	sent: <RiCheckFill color="gray" size={18} />,
	delivered: <RiCheckDoubleFill color="gray" size={18} />,
	read: <RiCheckDoubleFill color="teal" size={18} />,
};

const MessageText = ({ input }) => (
	<span
		style={{ color: "#393E46", fontWeight: "600", whiteSpace: "break-spaces" }}
		dangerouslySetInnerHTML={{
			__html: anchorme({
				input,
				options: {
					attributes: {
						target: "_blank",
					},
				},
			}),
		}}></span>
);

function Message(props) {
	const clickImageModalRef = React.createRef();
	const messageRef = React.createRef();

	const [status, setStatus] = useState("");
	const [listening, setListening] = useState(false);

	const dbRef = db.ref(`chatMessages/${props.chatId}/${props.messageKey}`);

	const removeStatusListener = () => {
		dbRef.off();
		setListening(false);
	};

	useEffect(() => {
		if (props.curr_chat.status && props.curr_chat.status !== "read" && props.fromMe) {
			dbRef.on("value", (snap) => {
				const status = snap.val().status;
				setStatus(status);
				setListening(true);
				if (status === "read") {
					removeStatusListener();
				}
			});
		}
	}, []);
	useEffect(() => {
		if (listening) {
			const isLastMessageByUs = props.isLastMessageByUs;

			if (status === "delivered" && !isLastMessageByUs) {
				removeStatusListener();
			}
		}
	}, [listening, props.isLastMessageByUs, status]);

	const setMessageTransform = (deltaX) => {
		if (deltaX === 0) {
			messageRef.current.style.transition = "ease-in-out 0.2s all";
		} else {
			messageRef.current.style.transition = "none";
		}
		const transformStyle = `translateX(${deltaX}px)`;
		messageRef.current.style.webkitTransform = transformStyle;
		messageRef.current.style.MozTransform = transformStyle;
		messageRef.current.style.msTransform = transformStyle;
		messageRef.current.style.transform = transformStyle;
	};

	const swipeHandlers = useSwipeable({
		onSwiping: ({ dir: swipeDirection, deltaX }) => {
			if (swipeDirection === "Right") {
				setMessageTransform(deltaX);
			}
		},
		onSwipedRight: (eventData) => {
			setMessageTransform(0);
			props.getReplyMessage(props.curr_chat.message, props.fromMe);
		},
	});

	function handleImage(src) {
		clickImageModalRef.current.visibleModal();
	}

	const MessageContent = ({ message }) => {
		switch (message.type) {
			case "image":
				return (
					<>
						<img
							id="inline_img"
							src={message.message}
							onClick={() => handleImage(message.message)}
							alt="new"
						/>
						<ClickImageModal ref={clickImageModalRef} imageSRC={message.message} />
						<div>{message.caption}</div>
					</>
				);

			case "document":
				return (
					<a href={message.message} without rel="noopener noreferrer" target="_blank">
						{message.caption}.pdf
					</a>
				);

			case "video":
				return <video title="New Video" controls height="200px" width="300px" src={message.message}></video>;

			case "audio":
				return (
					<figure className="audio">
						<figcaption>Audio:</figcaption>
						<audio controls src={message.message}>
							Your browser does not support the
							<code>audio</code> element.
						</audio>
					</figure>
				);

			default:
				return <MessageText input={message.message} />;
		}
	};

	const _status = status !== "" ? status : props.curr_chat.status;
	const todayDate = dayjs().format("D-M-YYYY");

	return (
		<Row
			style={{ padding: "0.25em 1em", marginRight: "-200px !important" }}
			{...swipeHandlers}>
			{props.fromMe && <Col flex="auto"></Col>}
			<Col
				ref={messageRef}
				style={{
					maxWidth: "80%",
					padding: "1em",
					borderRadius: "5px",
					background: props.fromMe ? "#BEE5E8" : "#fefefe",
				}}>
				<MessageContent message={props.curr_chat} />
				<Row justify="end" align="bottom" gutter={12}>
					<Col
						style={{
							color: "#393E46",
							fontWeight: "600",
							opacity: 0.5,
							margin: "0.25em 0.5em 0.25em 0",
						}}>
						{todayDate === props.curr_chat.messageDate ? "" : props.curr_chat.messageDate}{" "}
						{props.curr_chat.messageTime}
					</Col>
					{props.fromMe && <Col>{statusIcons[_status]}</Col>}
				</Row>
			</Col>
		</Row>
	);
}

export default React.forwardRef((props, ref) => <Message {...props} crate={ref} />);
