import React, { useEffect, useRef } from "react";
import Chart from "chart.js";
Chart.defaults.global.defaultFontFamily = "'Open Sans', sans-serif";

let analyticsLineChart;

export default function Graph({ data }) {
	const chartRef = useRef();

	const buildChart = () => {
		const myChartRef = chartRef.current.getContext("2d");

		if (typeof analyticsLineChart !== "undefined") analyticsLineChart.destroy();

		analyticsLineChart = new Chart(myChartRef, {
			type: "line",
			data,
			options: {
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
							},
						},
					],
                },
                maintainAspectRatio: false,
			},
		});
	};
	useEffect(buildChart);

	return <canvas ref={chartRef} />;
}
