import React, { Component, createRef } from "react";
import { db } from "../../config/_firebase.js";
import ChatWindowCard from "./ChatListCard";
import SkeletonCard from "./Skeleton";
import { myContext } from "./myContext";
import { Menu } from "antd";
import dayjs from "dayjs";
import { withRouter } from "react-router";
import { sendAmplitudeData } from "../../config/amplitudeFunctions";
import { CONTACT_SELECTED, IMPORTANT_CHANGED } from "../../config/amplitudeEvents.js";
import algoliaIndex from "../../config/_algolia";

const NullComponent = () => null;

export class ChatWindowV2 extends Component {
	static contextType = myContext;
	constructor(props) {
		super(props);

		this.mounted = false;
		this.unreadMessages = [];
		this.count = 0;
		this.alert = false;
		this.lastMessageTimestamp = "";
		this.state = {
			loading: true,
			contactLoaded: false,
			contact: {},
			contactName: "",
			chats: [],
			count: 0,
			attendedBy: "",
			attendedById: "",
			alert: false,
			tags: "",
			lastMessageDate: "",
			lastMessageTime: "",
			lastMessage: "",
			hospital_id: localStorage.getItem("hospital"),
			chatObj: {}
		};
		this.cardRef = createRef();
	}

	componentDidMount() {
		this.mounted = true;
		// if (this.props.loadCard) {
		this.addContactListener(this.props.contactId);
		this.addChatListenerV2(this.props.id);
		this.addLastMessageListener(this.props.id);
		// }
	}

	componentWillUnmount() {
		// db.ref(`Chats/${this.props.id}`).off();
		// db.ref(`Users/${this.props.contactId}`).off();
		// db.ref(`v2_userChats/${this.context.adminId}/${this.props.contactId}`).off();
		this.mounted = false;
	}

	addLastMessageListener = (chatId) => {
		db.ref(`Chats/${chatId}`).on("value", (snapshot) => {
			const msgBody = snapshot.val();
			if (msgBody !== null) {
				this.lastMessageTimestamp = msgBody.timestamp;
				var currMessage = msgBody.lastMessageSent;
				if (currMessage.startsWith("https://")) currMessage = "Media";

				const ms = msgBody.timestamp * 1000;

				const currDate = dayjs(ms).format("DD/MM/YYYY");
				const currTime = dayjs(ms).format("hh:mm A");
				this.setState({
					lastMessage: currMessage,
					lastMessageDate: currDate,
					lastMessageTime: currTime,
				});
			}
			// this.props.sendLastMessageTimestamp(chatId, this.lastMessageTimestamp);
		});
	};

	addContactListener = (id) => {
		if (this.mounted) {
			db.ref(`Users/${id}`).on("value", (snapshot) => {
				if (snapshot.exists()) {
					const contact_ = snapshot.val();
					this.setState({
						contact: contact_,
						contactName: contact_.userDisplayName,
						contactLoaded: true,
					});
				}
			});
		}
	};

	addChatListenerV2 = (chatId) => {
		db.ref(`v2_userChats/${this.context.adminId}/${this.props.contactId}`).on("value", (snapshot) => {
			if (snapshot.exists()) {
				const curr = snapshot.val();
				let unread = curr.unread;
				let assistanceRequired = curr.callback;
				if (unread === undefined) unread = 0;
				if (assistanceRequired === undefined) assistanceRequired = false;
				this.setState({
					loading: false,
					count: unread,
					alert: assistanceRequired,
					tags: curr.tags ?? "",
					attendedBy: curr.attendedBy,
					attendedById: curr.attendedById,
					chatObj: curr
				});
			}
		});
	};

	markUnreadMessages = () => {
		if (!this.state.loading) {
			db.ref(`v2_userChats/${this.context.adminId}/${this.props.contactId}`).update({
				unread: 0,
				callback: false,
			});
			if (this.state.attendedById !== "") {
				db.ref(`v2_assignedChats/${this.context.adminId}/${this.props.contactId}`).update({
					unread: 0,
					callback: false,
				});
				db.ref(`v2_agentWiseChats/${this.state.attendedById}/${this.props.contactId}`).update({
					unread: 0,
					callback: false,
				});
			}
		}
	};

	assignUserToAdmin = (adminName, adminID_) => {
		let obj = this.state.chatObj;
		let attendedByName = adminName;
		obj['attendedById'] = adminID_;
		obj['attendedBy'] = attendedByName;
		obj['auto_reply'] = 'false';
		obj['auto_reply_ts'] = Math.round(Date.now() / 1000).toString();
		db.ref(`v2_agentWiseChats/${this.state.attendedById}/${this.props.contactId}`).set(null);
		db.ref(`v2_agentWiseChats/${adminID_}/${this.props.contactId}`).set({
			chatId: this.props.id,
			timestamp: this.props.ts,
			neg_ts: -this.props.ts,
		});
		db.ref(`v2_assignedChats/${this.context.adminId}/${this.props.contactId}`).set({
			chatId: this.props.id,
			timestamp: this.props.ts,
			neg_ts: -this.props.ts,
		});
		db.ref(`Users/${this.props.contactId}`).update({
			attendedBy: adminName,
			attendedById: adminID_,
		});
		algoliaIndex.partialUpdateObject({
			'objectID': this.props.id,
			'attendedById': adminID_
		});
		this.setState({
			attendedBy: adminName,
			attendedById: adminID_,
		});
	};

	handleCardClick = (e) => {
		const blackList = ["BUTTON", "svg", "path"];
		if (
			e.target &&
			this.cardRef &&
			this.cardRef.current.contains(e.target) &&
			!blackList.includes(e.target.nodeName)
		) {
			if (this.state.contactLoaded) {
				sendAmplitudeData(CONTACT_SELECTED, {
					contactNumber: this.state.contact.userPhone,
				});
				this.props.history.push(
					`/dashboard/conversations/conversation?chatId=${this.props.id}&contactId=${this.props.contactId}`,
					{ adminList: this.context.adminList }
				);
				this.markUnreadMessages();
				this.count = 0;
				this.unreadMessages = [];
				this.alert = false;
				if (this.mounted) {
					this.setState({
						count: 0,
						alert: false,
					});
				}
			}
		}
	};

	toggleImportantTag = () => {
		let tagString = this.state.tags;

		if (/~?Important~?/.test(tagString)) {
			this.removeTag("Important");
			sendAmplitudeData(IMPORTANT_CHANGED, {
				type: "unmarked",
			});
		} else {
			sendAmplitudeData(IMPORTANT_CHANGED, {
				type: "marked",
			});
			tagString = tagString + "~Important";
			db.ref(`v2_userChats/${this.context.adminId}/${this.props.contactId}/tags`).set(tagString);
			this.setState({ tags: tagString });
		}
	};

	removeTag = (tag) => {
		const tagString = this.state.tags;
		const tags = tagString.split("~");
		const index = tags.indexOf(tag);
		if (index > -1) {
			tags.splice(index, 1);
			const updatedTagString = tags.join("~");
			db.ref(`v2_userChats/${this.context.adminId}/${this.props.contactId}/tags`).set(updatedTagString);
			this.setState({ tags: updatedTagString });
		}
	};

	render() {
		if (this.state.loading) {
			return <SkeletonCard />;
		} else {
			const chatInfo = {
				contactName: this.state.contactName,
				lastMessage: this.state.lastMessage,
				lastMessageTime: this.state.lastMessageTime,
				lastMessageDate: this.state.lastMessageDate,
			};
			const menu = (
				<Menu mode="vertical">
					<Menu.Item onClick={this.toggleImportantTag} key="toggle-important">
						{/~?Important~?/.test(this.state.tags) ? "Unmark" : "Mark"} Important
					</Menu.Item>
					<Menu.SubMenu key="assign-to" title="Assign to" icon={<NullComponent />}>
						{this.context.adminList.map(({ uid, name }) => (
							<Menu.Item key={uid} onClick={() => this.assignUserToAdmin(name, uid)}>
								{name}
							</Menu.Item>
						))}
					</Menu.SubMenu>
				</Menu>
			);
			return (
				<div ref={this.cardRef} onClick={this.handleCardClick}>
					{chatInfo.contactName ? (
						<ChatWindowCard
							assistanceRequired={this.state.alert}
							chatInfo={chatInfo}
							count={this.state.count}
							attendedBy={this.state.attendedBy}
							tags={this.state.tags}
							onCloseTags={this.removeTag}
							menu={menu}
						/>
					) : (
						<SkeletonCard />
					)}
				</div>
			);
		}
	}
}
ChatWindowV2.contextType = myContext;

export default withRouter(ChatWindowV2);
