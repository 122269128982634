import React, { useState } from "react";
import { Modal } from "antd";

export default function ClickImageModal({ imageSRC }) {
	const [visible, setVisible] = useState(false);

	const toggleVisibility = () => setVisible((vis) => !vis);

	return (
		<Modal
			visible={visible}
			title="Image Preview"
			width="50%"
			onOk={toggleVisibility}
			onCancel={toggleVisibility}
			footer={null}>
			<img src={imageSRC} alt="sample" id="modal_image" />
		</Modal>
	);
}
