const dataType = "text/csv;charset=utf-8;";

// Excerpted and extended from https://stackoverflow.com/a/24922761/12172493
export default function downloadCSV(csvData, filename) {
	const csvFile = csvData.toString();
	const blob = new Blob([csvFile], { type: dataType });

    // IE 10+
	if (navigator.msSaveBlob) {
		navigator.msSaveBlob(blob, filename);
	} else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            // For browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", filename);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
        } else {
            // Really old browsers
			console.log("Download attr not supported");
			window.open(encodeURI(`data:${dataType},${csvFile}`));
			window.alert("Please rename the downloaded file from 'download to 'download.csv'");
		}
	}
}
