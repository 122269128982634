import { Button, Result } from "antd";
import { FrownOutlined, ReloadOutlined } from "@ant-design/icons";

import "./offline.css";

const OfflinePage = () => {
	const reloadPage = () => {
		window.location.reload();
	};

	const props = {
		title: "It looks like you're offline",
		subTitle: "Please check your internet connection and try again",
		icon: <FrownOutlined />,
		extra: (
			<Button type="dashed" icon={<ReloadOutlined />} onClick={reloadPage}>
				Refresh
			</Button>
		),
	};
	return (
		<div className="offline-page">
			<Result {...props} />
		</div>
	);
};

export default OfflinePage;
