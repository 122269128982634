import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/_firebase";

import { MessageOutlined, PieChartOutlined, UserOutlined, MailOutlined, LogoutOutlined } from "@ant-design/icons";
import { Drawer, Menu } from "antd";

import "./pagemenu.css";

export default function PageMenu({ adminId, isVisible, toggleVisibility }) {
	const [numberOfContacts, setNumberOfContacts] = useState(null);

	useEffect(() => {
		const path = "v2_userChats/" + adminId;
		if (adminId) {
			db.ref(path)
				.orderByChild("neg_ts")
				.on("value", (snapshot) => {
					setNumberOfContacts(snapshot.numChildren());
				});
		}
	}, [adminId]);

	const handleLogout = () => {
		const token = localStorage.getItem("notificationToken");
		const adminUid = localStorage.getItem("adminUID");
		if (token) {
			db.ref(`pwa_token_list/${adminId}/${adminUid}/${token}`).set(null);
		}
		localStorage.clear();
		window.location.reload();
	};

	return (
		<Drawer
			className="page-menu"
			title={<span style={{ color: "#4EA2FF" }}>Menu</span>}
			visible={isVisible}
			closable={false}
			maskClosable
			onClose={toggleVisibility}>
			<Menu selectedKeys={[window.location.pathname.split("/")[2]]} mode="inline" theme="dark" onSelect={toggleVisibility}>
				<Menu.Item key="conversations" icon={<MessageOutlined />}>
					<Link to="/dashboard/conversations">
						<span style={{ fontWeight: 500 }}>Conversations</span>
					</Link>
				</Menu.Item>
				<Menu.Item key="contacts" icon={<UserOutlined />} title="Contacts">
					<Link to="/dashboard/contacts">
						<span style={{ fontWeight: 500 }}>Contacts</span>{" "}
						{numberOfContacts && <strong>({numberOfContacts})</strong>}
					</Link>
				</Menu.Item>
				<Menu.Item key="analytics" icon={<PieChartOutlined />}>
					<Link to="/dashboard/analytics">
						<span style={{ fontWeight: 500 }}>Analytics</span>
					</Link>
				</Menu.Item>
				<Menu.Item key="templates" icon={<MailOutlined />}>
					<Link to="/dashboard/templates">
						<span style={{ fontWeight: 500 }}>Templates</span>
					</Link>
				</Menu.Item>
				<Menu.Divider />
				<Menu.Item key="5" onClick={handleLogout} icon={<LogoutOutlined />}>
					<span style={{ fontWeight: 600 }}>Log Out</span>
				</Menu.Item>
			</Menu>
		</Drawer>
	);
}
