import React, { useEffect, useState } from "react";
import { db } from "../../config/_firebase.js";
import { myContext } from "./myContext";
import { useSwipeable } from "react-swipeable";
import { Tabs, Spin } from "antd";

import InfiniteList from "./InfiniteList.jsx";

import "./chatlist.css";
import TopBar from "./TopBar.jsx";
import FilterSidebar from "./FilterSidebar.jsx";
import { sendAmplitudeData } from "../../config/amplitudeFunctions.js";
import { FILTER_APPLIED, QUERY_SEARCHED } from "../../config/amplitudeEvents.js";
import WindowList from "./WindowList.jsx";

const { TabPane } = Tabs;

const adminPhoneNum = localStorage.getItem("phoneNum");

async function getAdminList() {
	const adminListRef = db.ref("UserCredentials");
	const adminListSnapshot = await adminListRef.orderByChild("phoneNumber").equalTo(adminPhoneNum).once("value");

	if (adminListSnapshot.exists()) {
		const adminList = Object.values(adminListSnapshot.val());
		return adminList;
	}
}

export default function ChatList(props) {
	const [loading, setLoading] = useState(true);
	const [adminList, setAdminList] = useState([]);
	const [filteringTags, setFilteringTags] = useState([]);
	const [filterType, setFilterType] = useState(""); // "tags" | "assistanceRequired" | "unread"
	const [searchQuery, setSearchQuery] = useState("");
	const [searchMode, setSearchMode] = useState("1");
	const [showUnread, setShowUnread] = useState(false);
	const [showAssistanceRequired, setShowAssistanceRequired] = useState(false);

	const [isFilterMenuVisible, setIsFilterMenuVisible] = useState(false);
	const [activeKey, setActiveKey] = useState("1");

	const swipeHandlers = useSwipeable({
		onSwipedLeft: () => {
			if (activeKey < 3) {
				setActiveKey((key) => (parseInt(key) + 1).toString());
			}
		},
		onSwipedRight: () => {
			if (activeKey > 1) {
				setActiveKey((key) => (parseInt(key) - 1).toString());
			}
		},
	});
	useEffect(() => {
		if (!adminList || (adminList && adminList.length === 0)) {
			getAdminList().then((_adminList) => {
				setLoading(false);
				setAdminList(_adminList);
			});
		}
	}, [adminList, props.adminId]);

	const toggleFilterMenuVisibility = () => setIsFilterMenuVisible((vis) => !vis);

	const onSearch = (val) => {
		if (val.trim() !== "") {
			sendAmplitudeData(QUERY_SEARCHED, {
				type: /^\d+$/g.test(val) ? "number" : "name", // todo: doesn't account for country codes
				query: val,
			});
		}
		setSearchQuery(val);
	};

	const changeSearchMode = (mode) => {
		setSearchMode(mode);
	}

	const handleFilter = (_filterType, _filteringTags = []) => {
		if (_filterType !== "") {
			sendAmplitudeData(FILTER_APPLIED, {
				type: _filterType,
				...(filterType === "tags" && { selectedTags: _filteringTags }),
			});
		}
		setFilterType(_filterType);
		if (_filterType === "tags") {
			setFilteringTags(_filteringTags);
		}
	};

	const handleFilterChange = (_filterType, _checked) => {
		console.log(_filterType, _checked);
		if (_filterType !== "") {
			sendAmplitudeData(FILTER_APPLIED, {
				type: _filterType,
			});
		}
		if (_filterType === "unread") {
			setShowUnread(_checked);
		}
		if (_filterType === "assistanceRequired") {
			setShowAssistanceRequired(_checked);
		}
	}

	if (loading || !props.adminId || !adminList || (adminList && adminList.length === 0)) {
		return (
			<div className="loader">
				<Spin size="large" />
			</div>
		);
	} else {
		return (
			<div {...swipeHandlers}>
				<myContext.Provider
					value={{
						adminList,
						adminId: props.adminId,
					}}>
					<FilterSidebar
						isVisible={isFilterMenuVisible}
						toggleVisibility={toggleFilterMenuVisibility}
						adminId={props.adminId}
						handleFilter={handleFilter}
						handleFilterChange={handleFilterChange}
						filterType={filterType}
						showUnread={showUnread}
						showAssistanceRequired={showAssistanceRequired}
					/>
					<TopBar
						toggleFilterMenuVisibility={toggleFilterMenuVisibility}
						togglePageMenuVisibility={props.togglePageMenuVisibility}
						onSearch={onSearch}
						changeSearchMode={changeSearchMode}
					/>
					<Tabs size="large" activeKey={activeKey} onTabClick={(key) => setActiveKey(key)}>
						<TabPane forceRender tab="ALL" key="1">
							<WindowList
								filterType={filterType}
								showUnread={showUnread}
								showAssistanceRequired={showAssistanceRequired}
								searchQuery={searchQuery}
								searchMode={searchMode}
								selectedTags={filteringTags}
								menuId="all"
								dbPath="v2_userChats/"
							/>
							{/* <InfiniteList selectedTags={filteringTags} menuId="all" dbPath="v2_userChats/" /> */}
						</TabPane>
						<TabPane tab="MINE" key="2">
							<InfiniteList menuId="agentWise" dbPath="v2_agentWiseChats/" />
						</TabPane>
					</Tabs>
				</myContext.Provider>
			</div>
		);
	}
}
