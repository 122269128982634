import { useState } from "react";
import { Dropdown, Menu, Typography, Button, Input } from "antd";
import { BiMessageAltDetail, BiMessageAdd } from "react-icons/bi";

const QuickRepliesDropdown = ({
	isAutoOn,
	templateMessages,
	onChoose,
	addTemplateMessage,
	deleteTemplateMessage,
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const [newTemplateMessage, setNewTemplateMessage] = useState("");
	const [touchStartTime, setTouchTime] = useState(0);

	const toggleVisible = () => setIsVisible((vis) => !vis);
	const unemptyStrings = (str) => str !== "";

	return (
		<Dropdown
			visible={isVisible}
			placement="topLeft"
			disabled={isAutoOn}
			onVisibleChange={toggleVisible}
			trigger={["click"]}
			overlayStyle={{ width: "95vw" }}
			getPopupContainer={(triggerNode) => triggerNode}
			overlay={
				<Menu>
					<Menu.ItemGroup title="Quick Replies (Long-tap to delete)">
						<Menu.Divider />
						{templateMessages.length > 0 ? (
							templateMessages.filter(unemptyStrings).map((message, i) => {
								return (
									<Menu.Item
										key={i}
										onClick={() => onChoose(message)}
										onTouchStart={({ timeStamp }) => setTouchTime(timeStamp)}
										onTouchEnd={({ timeStamp }) => {
											const duration = timeStamp - touchStartTime;
											if (duration > 500) {
												deleteTemplateMessage(message);
												onChoose("");
											}
										}}
										style={{
											borderBottom: "1px solid #dddddd",
											padding: "1em",
										}}>
										<Typography.Paragraph style={{ marginBottom: "0", whiteSpace: "break-spaces" }}>
											{message}
										</Typography.Paragraph>
									</Menu.Item>
								);
							})
						) : (
							<Menu.Item>
								<i>No Template Messages found.</i>
							</Menu.Item>
						)}
					</Menu.ItemGroup>
					<Menu.Divider />
					<Menu.Item>
						<Input
							size="large"
							placeholder="Add a Message"
							value={newTemplateMessage}
							onChange={(e) => setNewTemplateMessage(e.target.value)}
							addonAfter={
								<Button
									className="icon-button"
									onClick={() => {
										if (newTemplateMessage !== "") {
											addTemplateMessage(newTemplateMessage);
											setNewTemplateMessage("");
										}
									}}>
									<BiMessageAdd size={20} />
								</Button>
							}
						/>
					</Menu.Item>
				</Menu>
			}>
			<Button className="icon-button" disabled={isAutoOn}>
				<BiMessageAltDetail size={22} />
			</Button>
		</Dropdown>
	);
};

export default QuickRepliesDropdown;
