import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Tag } from "antd";

import { db } from "../../config/_firebase";
import { sendAmplitudeData } from "../../config/amplitudeFunctions";
import { IMPORTANT_CHANGED, TAGS_CHANGED } from "../../config/amplitudeEvents";

function hashCode(str) {
	// java String#hashCode
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
}

function getHexFromHash(i) {
	const c = (i & 0x00ffffff).toString(16).toUpperCase();
	return "#" + c.padStart(6, "0");
}

export default function TagManagerModal({ contactID, adminID }) {
	const [isOpen, setIsOpen] = useState(false);
	const [touchStartTime, setTouchTime] = useState(0);

	const [tags, setTags] = useState([]);
	const [newTag, setNewTag] = useState("");

	useEffect(() => {
		const unsubsribe = db.ref(`v2_userChats/${adminID}/${contactID}/tags`).on("value", (snapshot) => {
			if (snapshot) {
				const tagStr = snapshot.val();
				const uniqueTags = tagStr.split("~").filter((tag) => tag !== "");
				setTags(uniqueTags);
			}
		});
		return () => {
			unsubsribe();
		};
	}, [adminID, contactID]);

	const toggleModal = () => {
		setIsOpen((prevStateOpen) => !prevStateOpen);
	};

	const removeTag = (tagToBeRemoved) => {
		if (tagToBeRemoved.toLowerCase() === "important") {
			sendAmplitudeData(IMPORTANT_CHANGED, {
				type: "unmarked",
			});
		} else {
			sendAmplitudeData(TAGS_CHANGED, {
				type: "removed",
				value: tagToBeRemoved,
			});
		}
		const updatedTagString = tags.filter((tag) => tag !== tagToBeRemoved).join("~");
		db.ref(`v2_userChats/${adminID}/${contactID}/tags`).set(updatedTagString);
	};
	const addTag = (tagToBeAdded) => {
		if (tagToBeAdded.toLowerCase() === "important") {
			sendAmplitudeData(IMPORTANT_CHANGED, {
				type: "unmarked",
			});
		} else {
			sendAmplitudeData(TAGS_CHANGED, {
				type: "removed",
				value: tagToBeAdded,
			});
		}
		if (!tags.includes(tagToBeAdded)) {
			const updatedTagString = [...tags, tagToBeAdded].join("~");
			db.ref(`v2_userChats/${adminID}/${contactID}/tags`).set(updatedTagString);
		}
	};

	return (
		<div>
			<Button onClick={toggleModal}>Manage Tags</Button>
			<Modal
				cancelText="Close"
				okText="Add"
				visible={isOpen}
				title={<span style={{ fontWeight: 600 }}>Manage Tags </span>}
				closable={false}
				onCancel={toggleModal}
				okButtonProps={{ disabled: newTag.trim() === "" }}
				onOk={() => {
					addTag(newTag.trim());
					setNewTag("");
				}}>
				<span>
					<i>Long tap on a tag to remove.</i>
				</span>
				<div style={{ margin: "1em 0" }}>
					{tags.map((tag) => {
						const hexColor = getHexFromHash(hashCode(tag));
						return (
							<Tag
								key={tag}
								onTouchStart={({ timeStamp }) => setTouchTime(timeStamp)}
								onTouchEnd={({ timeStamp }) => {
									const duration = timeStamp - touchStartTime;
									if (duration > 300) {
										removeTag(tag);
									}
								}}
								style={{
									backgroundColor: hexColor + "88",
									color: "#f5f5f5",
									margin: "0.25em",
									fontSize: "larger",
									padding: "0.5em",
								}}>
								{tag + "     "}
							</Tag>
						);
					})}
				</div>
				<Input
					value={newTag}
					placeholder="Add New Tag"
					style={{ borderRadius: "30pt", padding: "1em" }}
					onChange={(e) => setNewTag(e.target.value)}
				/>
			</Modal>
		</div>
	);
}
