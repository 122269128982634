export const LOGGED_IN = "LOGGED_IN"; // { adminName, hospitalName }
export const CONTACT_SELECTED = "CONTACT_SELECTED"; // { contactNumber }
export const MESSAGE_SENT = "MESSAGE_SENT"; // { value, recipient: phone number, intent, bot sent: }
export const TAGS_CHANGED = "TAGS_CHANGED"; // { type: "added"/"removed", value }
export const IMPORTANT_CHANGED = "IMPORTANT_CHANGED"; // {type: "marked"/"unmarked"}
export const QUERY_SEARCHED = "QUERY_SEARCHED"; // { type: "number"/"name", query: "" }
export const FILTER_APPLIED = "FILTER_APPLIED"; // { type: tags/unread/assistanceRequired, tags: [] }
export const AUTO_CHANGED = "AUTO_CHANGED"; 
export const CSV_DOWNLOADED = "CSV_DOWNLOADED"; // { length }
export const QUICK_REPLY_SELECTED = "QUICK_REPLY_SELECTED"; // { length }
export const REPLIED_TO_MESSAGE = "REPLIED_TO_MESSAGE"; // { length }
export const ATTACHMENT_SENT = "ATTACHMENT_SENT"; // { length }
export const ASSIGNED_TO = "ASSIGNED_TO"; // { length }
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED"; // { value, phoneNumber, intent }