import React from "react";

import { Row, Col, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import downloadCSV from "../Common/downloadCSV";
import { unparse as parseToCSV } from "papaparse";
import { sendAmplitudeData } from "../../config/amplitudeFunctions";
import { CSV_DOWNLOADED } from "../../config/amplitudeEvents";
import dayjs from "dayjs";

const formatDate = (tsInMs) => dayjs(tsInMs).format("DD/MM/YYYY hh:mm A");

const exportCSV = (data) => {
	const fields = ["Name", "Phone", "Timestamp"];
	const csvData = parseToCSV({
		fields,
		data: data.map(({ id, ...row }) => Object.values({ ...row, timestamp: formatDate(row.timestamp) })),
	});
	downloadCSV(csvData, "Contacts.csv");
	sendAmplitudeData(CSV_DOWNLOADED, { length: data.length });
};

const UtilityBar = ({ selectedContacts, SelectAllCheckBox, SortButton }) => {
	return (
		<Row
			align="middle"
			style={{ background: "#f5f5f5", border: "none", padding: "1em 1em 0 1.5em", width: "100%" }}>
			<Col span={12}>{SelectAllCheckBox}</Col>
			<Col span={12} style={{ textAlign: "right" }}>
				<Button
					type="primary"
					size="large"
					disabled={selectedContacts.length <= 0}
					icon={<DownloadOutlined style={{ fontSize: "1em" }} />}
					onClick={() => exportCSV(selectedContacts)}>
					Export as CSV
				</Button>
			</Col>
		</Row>
	);
};

export default UtilityBar;
