import React, { Component } from "react";
import { db } from "../../config/_firebase.js";
import SkeletonCard from "./Skeleton";
import ChatWindowV2 from "./ChatWindowV2.jsx";
import { myContext } from "./myContext";

const adminUID = localStorage.getItem("adminUID");

const getBlankWindow = () => {
	return (
		<div className="skele_card">
			<SkeletonCard />
		</div>
	);
};

export class InfiniteList extends Component {
	constructor(props) {
		super(props);
		this.currStart = 0;
		this.windowLimit = 30;
		this.currEnd = this.currStart + this.windowLimit;
		this.chatKeys = {};
		this.contactKeys = [];
		this.downScrollLimit = 0.85;
		this.upScrollLimit = 0.0;
		this.fraction = 0.0;
		this.selectedTags = [];
		this.state = {
			chatWindows: [],
			loading: false,
			showAssistanceRequired: false,
			showUnread: false,
			filterOn: false,
			searchOn: false,
			searchQuery: "",
		};
		this.chatWindowArr = [];
	}

	componentDidMount() {
		const path = this.props.dbPath + (this.props.menuId === "agentWise" ? adminUID : this.context.adminId);
		this.addChatsListener(path, this.props.menuId);
	}
	componentDidUpdate() {
		if (this.props.selectedTags !== undefined) {
			if (this.props.selectedTags.length !== this.selectedTags.length) {
				this.selectedTags = this.props.selectedTags;
				this.applyFilter(this.selectedTags);
			} else {
				for (var i = 0; i < this.selectedTags.length; i++) {
					if (this.selectedTags[i] !== this.props.selectedTags[i]) {
						this.selectedTags = this.props.selectedTags;
						this.applyFilter(this.selectedTags);
					}
				}
			}
		}
	}

	applyFilter = (tags) => {
		this.currStart = 0;
		this.currEnd = this.currStart + this.windowLimit;
		if (tags.length === 0) {
			this.setState(
				{
					filterOn: false,
				},
				() => {
					this.addChatWindows(this.currStart, this.currEnd);
				}
			);
		} else {
			this.setState(
				{
					filterOn: true,
					loading: true,
				},
				() => {
					this.addChatWindows(this.currStart, this.currEnd);
				}
			);
		}
	};

	performSearch = (value) => {
		this.currStart = 0;
		this.currEnd = this.currStart + this.windowLimit;
		value = value.toLowerCase();
		if (value !== "") {
			this.setState(
				{
					searchOn: true,
					searchQuery: value,
				},
				() => {
					this.addChatWindows(this.currStart, this.currEnd);
				}
			);
		} else {
			this.setState(
				{
					searchOn: false,
					searchQuery: "",
				},
				() => {
					this.addChatWindows(this.currStart, this.currEnd);
				}
			);
		}
	};

	addChatsListener = (path, menuId) => {
		db.ref(path)
			.orderByChild("neg_ts")
			.on("value", (snapshot) => {
				var i = 0;
				var shouldUpdate = true;
				snapshot.forEach((childSnapshot) => {
					const contactDetails = childSnapshot.val();
					if (contactDetails.chatId) {
						if (i === 0) {
							const currKey = childSnapshot.key;
							if (this.contactKeys.length > 0 && currKey === this.contactKeys[0]) {
								shouldUpdate = false;
							} else {
								this.contactKeys = [];
								this.chatKeys = {};
							}
						}
						if (shouldUpdate) {
							this.contactKeys.push(childSnapshot.key);
							this.chatKeys[childSnapshot.key] = contactDetails;
						} else {
							this.chatKeys[childSnapshot.key] = contactDetails;
						}
						i++;
					}
				});
				if (shouldUpdate && !this.state.filterOn) {
					this.addChatWindows(this.currStart, Math.min(this.currEnd, this.contactKeys.length));
				}
			});
	};

	addChatWindows = (start, end) => {
		var chatWindowArr_ = [];
		if (!this.state.showAssistanceRequired && !this.state.showUnread) {
			for (var i = 0; i < start; i++) {
				const newWindow = getBlankWindow();
				if (i > chatWindowArr_.length) chatWindowArr_.push(newWindow);
				else chatWindowArr_[i] = newWindow;
			}
		}
		i = start;
		var counter = start;
		while (i < end && counter < this.contactKeys.length) {
			const contactId = this.contactKeys[counter];
			counter++;
			if (
				this.state.showAssistanceRequired &&
				(this.chatKeys[contactId] === undefined ||
					this.chatKeys[contactId].callback === undefined ||
					!this.chatKeys[contactId].callback)
			) {
				continue;
			} else if (
				this.state.showUnread &&
				(this.chatKeys[contactId] === undefined ||
					this.chatKeys[contactId].unread === undefined ||
					this.chatKeys[contactId].unread === 0)
			) {
				continue;
			} else if (this.state.filterOn) {
				const currTags = this.chatKeys[contactId].tags;
				if (currTags !== undefined) {
					const currTagsArr = currTags.split("~");
					for (var j = 0; j < this.selectedTags.length; j++) {
						if (currTagsArr.includes(this.selectedTags[j])) {
							const newWindow = this.getChatWindow(contactId, true);
							chatWindowArr_[i] = newWindow;
							i++;
							break;
						}
					}
				}
				continue;
			} else if (this.state.searchOn) {
				var currName = this.chatKeys[contactId].name;
				if (currName !== undefined) {
					currName = currName.toLowerCase();
					if (currName.includes(this.state.searchQuery)) {
						const newWindow = this.getChatWindow(contactId, true);
						chatWindowArr_[i] = newWindow;
						i++;
					}
				}
				continue;
			}

			const newWindow = this.getChatWindow(contactId, true);
			chatWindowArr_.push(newWindow);
			i++;
		}
		if (!this.state.showAssistanceRequired && !this.state.showUnread) {
			for (i = end; i < chatWindowArr_.length; i++) {
				const newWindow = getBlankWindow();
				chatWindowArr_[i] = newWindow;
			}
		} else {
			this.currEnd = chatWindowArr_.length;
			this.fraction = 0;
			this.downScrollLimit = 0.85;
			this.upScrollLimit = 0.0;
		}
		this.chatWindowArr = chatWindowArr_;
		this.setState({
			loading: false,
			chatWindows: chatWindowArr_,
		});
	};

	handleUpScroll = (newStart, newEnd) => {
		var oldStart = this.currStart;
		var chatWindowArr_ = this.chatWindowArr;
		for (var i = newStart; i < oldStart; i++) {
			const contactId = this.contactKeys[i];
			if (
				this.state.showAssistanceRequired &&
				(this.chatKeys[contactId].callback === undefined || !this.chatKeys[contactId].callback)
			)
				continue;
			else if (
				this.state.showUnread &&
				(this.chatKeys[contactId].unread === undefined || this.chatKeys[contactId].unread === 0)
			)
				continue;
			else if (this.state.filterOn) {
				const currTags = this.chatKeys[contactId].tags;
				if (currTags !== undefined) {
					const currTagsArr = currTags.split("~");
					for (var j = 0; j < this.selectedTags.length; j++) {
						if (currTagsArr.includes(this.selectedTags[j])) {
							const newWindow = this.getChatWindow(contactId, true);
							chatWindowArr_[i] = newWindow;
							break;
						}
					}
				}
				continue;
			} else if (this.state.searchOn) {
				var currName = this.chatKeys[contactId].name;
				if (currName !== undefined) {
					currName = currName.toLowerCase();
					if (currName.includes(this.state.searchQuery)) {
						const newWindow = this.getChatWindow(contactId, true);
						chatWindowArr_[i] = newWindow;
						i++;
					}
				}
				continue;
			}
			const newWindow = this.getChatWindow(contactId, true);
			chatWindowArr_[i] = newWindow;
		}

		var oldEnd = this.currEnd;
		for (i = newEnd; i < oldEnd; i++) {
			const newWindow = getBlankWindow();
			chatWindowArr_[i] = newWindow;
		}

		this.currStart = newStart;
		this.currEnd = newEnd;
		this.chatWindowArr = chatWindowArr_;
		this.upScrollLimit = Math.max(0.1, this.currStart / this.chatWindowArr.length + 0.1);
		this.downScrollLimit = Math.max(0.1, this.currEnd / this.chatWindowArr.length);
		this.setState({
			chatWindows: chatWindowArr_,
		});
	};

	handleDownScroll = (newStart, newEnd) => {
		var oldStart = this.currStart;
		var chatWindowArr_ = this.chatWindowArr;
		for (var i = oldStart; i < newStart - 10; i++) {
			const newWindow = getBlankWindow();
			chatWindowArr_[i] = newWindow;
		}
		var oldEnd = this.currEnd;
		for (i = oldEnd; i < newEnd; i++) {
			const contactId = this.contactKeys[i];
			if (
				this.state.showAssistanceRequired &&
				(this.chatKeys[contactId].callback === undefined || !this.chatKeys[contactId].callback)
			)
				continue;
			else if (
				this.state.showUnread &&
				(this.chatKeys[contactId].unread === undefined || this.chatKeys[contactId].unread === 0)
			)
				continue;
			else if (this.state.filterOn) {
				const currTags = this.chatKeys[contactId].tags;
				if (currTags !== undefined) {
					const currTagsArr = currTags.split("~");
					for (var j = 0; j < this.selectedTags.length; j++) {
						if (currTagsArr.includes(this.selectedTags[j])) {
							const newWindow = this.getChatWindow(contactId, true);
							if (i >= chatWindowArr_.length) chatWindowArr_.push(newWindow);
							else chatWindowArr_[i] = newWindow;
							break;
						}
					}
				}
				continue;
			} else if (this.state.searchOn) {
				var currName = this.chatKeys[contactId].name;
				if (currName !== undefined) {
					currName = currName.toLowerCase();
					if (currName.includes(this.state.searchQuery)) {
						const newWindow = this.getChatWindow(contactId, true);
						chatWindowArr_[i] = newWindow;
						i++;
					}
				}
				continue;
			}
			const newWindow = this.getChatWindow(contactId, true);
			if (i >= chatWindowArr_.length) chatWindowArr_.push(newWindow);
			else chatWindowArr_[i] = newWindow;
		}
		this.currStart = newStart;
		this.currEnd = newEnd;
		this.chatWindowArr = chatWindowArr_;
		this.upScrollLimit = Math.max(0.1, this.currStart / this.chatWindowArr.length + 0.1);
		this.downScrollLimit = Math.max(0.1, this.currEnd / this.chatWindowArr.length - 0.1);
		this.setState({
			chatWindows: chatWindowArr_,
		});
	};

	getChatWindow = (contactId, loadCard) => {
		if (this.chatKeys[contactId] === undefined) {
			return getBlankWindow();
		}
		const chatId = this.chatKeys[contactId].chatId;
		const ts = this.chatKeys[contactId].timestamp;
		const currMenuId = this.props.menuId + (chatId || contactId);
		const newWindow = (
			<ChatWindowV2
				adminID={this.context.adminId}
				id={chatId}
				key={currMenuId}
				ts={ts}
				menuId={currMenuId}
				contactId={contactId}
				loadCard={loadCard}
			/>
		);
		return newWindow;
	};

	modifyChatWindowList = () => {
		this.currStart = Math.min(this.currStart + 10, this.contactKeys.length - this.windowLimit);
		this.currEnd = this.currStart + this.windowLimit;
		this.addChatWindows(this.currStart, this.currEnd);
	};

	handleScroll = (e) => {
		const currFraction = e.target.scrollTop / e.target.scrollHeight;
		if (currFraction > this.downScrollLimit && currFraction > this.fraction) {
			const newStart = Math.min(this.currStart + 10, this.contactKeys.length - this.windowLimit);
			const newEnd = newStart + this.windowLimit;
			this.handleDownScroll(newStart, newEnd);
		} else if (currFraction < this.upScrollLimit && currFraction < this.fraction) {
			if (this.currStart > 0) {
				const newStart = Math.max(0, this.currStart - 20);
				const newEnd = newStart + this.windowLimit;
				this.handleUpScroll(newStart, newEnd);
			}
		}
		this.fraction = currFraction;
	};

	render() {
		return <div onScroll={this.handleScroll}>{this.state.chatWindows}</div>;
	}
}

InfiniteList.contextType = myContext;

export default InfiniteList;
