import React, { useState } from "react";

import { auth, db } from "../../config/_firebase";

import firebase from 'firebase';
import { Button, Form, Input, Row, Col, Typography } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import "./Login.css";

import stockImg from "../../assets/stock.jpg";
import logo from "../../assets/logo.png";
import { setAmplitudeUserId, sendAmplitudeData } from "../../config/amplitudeFunctions";
import { LOGGED_IN } from "../../config/amplitudeEvents";

const login = async (email, password) => {
	try {
		const result = await auth.signInWithEmailAndPassword(email, password);
		if (result) {			
			const dbRef = db.ref("UserCredentials");
			const adminSnapshot = await dbRef.orderByChild("email").equalTo(email).once("value");
			const adminData = Object.values(adminSnapshot.val())[0];
		
			localStorage.setItem("phoneNum", adminData.phoneNumber);
			localStorage.setItem("hospitalName", adminData.hospitalName);
			localStorage.setItem("adminEmail", adminData.email);
			localStorage.setItem("adminName", adminData.name);
			localStorage.setItem("adminUID", adminData.uid);
			localStorage.setItem("carrier", adminData.carrier);
			localStorage.setItem("token", result.user.refreshToken);
			if (adminData.department) {
				localStorage.setItem("adminDepartment", adminData.department);
			}

			setAmplitudeUserId(adminData.email);
			sendAmplitudeData(LOGGED_IN, {
				adminName: adminData.name,
				hospitalName: adminData.hospitalName,
			});

			return true;
		}
	} catch (error) {
		alert(error.message);
		console.log(error);
	}
};

const addTokenToDB = async (token) => {
	const phoneNum = localStorage.getItem("phoneNum");
	if (phoneNum) {
		const adminSnapshot = await db.ref('Users').orderByChild("userPhone").equalTo(phoneNum).once("value");
		if (adminSnapshot.exists()) {
			console.log("Adding token to db...")
			const adminId = Object.values(adminSnapshot.val())[0].id;
			const adminUid = localStorage.getItem("adminUID");
			db.ref(`pwa_token_list/${adminId}/${adminUid}/${token}`).set(true);	
		}
		window.location.reload();
	}
}

export default function LoginPage(props) {
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (values) => {
		setLoading(true);
		const isLoginSuccessful = await login(values.email, values.password);
		if (isLoginSuccessful) {
			// !history push doesn't load render the chatlist for some reason
			const message = firebase.messaging();
			if (Notification.permission !== 'granted') {
				Notification.requestPermission().then((permission) => {
					if (permission === "granted") {
						message.getToken().then((data) => {
							localStorage.setItem("notificationToken", data);
							addTokenToDB(data);
						});
					} else {
						window.location.reload();
					}
				});
			} else {
				message.getToken().then((data) => {
					localStorage.setItem("notificationToken", data);
					addTokenToDB(data);
				})
			}
		}
		setLoading(false);
	};

	return (
		<div className="login-page">
			<img alt="Splash" src={stockImg} className="splash-image" />
			<div className="login-area">
				<Typography.Title level={4} style={{ fontWeight: 700, paddingBottom: "1em" }}>
					Login To Account
				</Typography.Title>
				<Form size="large" layout="vertical" name="Login" onFinish={handleSubmit}>
					<Form.Item
						name="email"
						rules={[
							{
								required: true,
								message: "Please input your E-Mail!",
							},
						]}>
						<Input placeholder="E-Mail" addonBefore={<MailOutlined />} />
					</Form.Item>
					<br />
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: "Please input your password!",
							},
						]}>
						<Input.Password placeholder="Password" addonBefore={<LockOutlined />} />
					</Form.Item>
					<Form.Item>
        			<a className="login-form-forgot" href="/verification">
        			 Reset password
       				 </a>
     				 </Form.Item>

					<Row justify="space-between" align="middle" style={{ paddingTop: "2em" }}>
						<Col span={12}></Col>
						<Col span={12} style={{ textAlign: "right" }}>
							<Form.Item style={{ marginBottom: "0px" }}>
								<Button
									style={{ width: "100%" }}
									type="primary"
									size="large"
									htmlType="submit"
									loading={loading}>
									Log In
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
			<Row className="powered-by" justify="center">
				<Col span={3}>
					<img src={logo} className="logo" alt="Logo" />
				</Col>

				<Col span={12} offset={1}>
					<Typography.Text strong style={{ color: "#23408E" }}>
						Powered by Prescribe
					</Typography.Text>
					<br />
					<Typography.Link type="secondary" href="https://www.heyprescribe.com/" target="blank">
						www.heyprescribe.com
					</Typography.Link>
				</Col>
			</Row>
		</div>
	);
}
