import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { auth } from "../../config/_firebase";

import { Button, Form, Input, Row, Col, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import "./Reset-Password.css";

import stockImg from "../../assets/stock.jpg";
import logo from "../../assets/logo.png";

const resetPassword = async (password) => {
	try {
		const currentUser = auth.currentUser;
		await currentUser.updatePassword(password);
		return true;
	} catch (error) {
		alert(error.message);
		return false;
	}
};

export default function ResetPasswordPage(props) {
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const handleSubmit = async (values) => {
		setLoading(true);
		const isResetSuccessful = await resetPassword(values.password);
		console.log(isResetSuccessful);
		setLoading(false);
		if (isResetSuccessful) {
			// !history push doesn't load render the chatlist for some reason
			history.push('/login');
		}
	};

	return (
		<div className="login-page">
			<img alt="Splash" src={stockImg} className="splash-image" />
			<div className="login-area">
				<Typography.Title level={4} style={{ fontWeight: 700, paddingBottom: "1em" }}>
					Reset Your Password
				</Typography.Title>
				<Form size="large" layout="vertical" name="Login" onFinish={handleSubmit}>
				<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: "Please input your password!",
							},
						]}>
						<Input.Password placeholder="Password" addonBefore={<LockOutlined />} />
					</Form.Item>
					<br />
					<Form.Item
						name="confirm-password"
						rules={[
							{
								required: true,
								message: "Please confirm your password!",
							},
							({ getFieldValue }) => ({
								validator(rule, value) {
								  if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								  }
								  return Promise.reject('The two passwords that you entered do not match!');
								},
							  }),
						]}>
						<Input.Password placeholder="Confirm Password" addonBefore={<LockOutlined />} />
					</Form.Item>
				
					<Row justify="space-between" align="middle" style={{ paddingTop: "2em" }}>
						<Col span={12}></Col>
						<Col span={12} style={{ textAlign: "right" }}>
							<Form.Item style={{ marginBottom: "0px" }}>
								<Button
									style={{ width: "100%" }}
									type="primary"
									size="large"
									htmlType="submit"
									loading={loading}>
									Submit
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
			<Row className="powered-by" justify="center">
				<Col span={3}>
					<img src={logo} className="logo" alt="Logo" />
				</Col>

				<Col span={12} offset={1}>
					<Typography.Text strong style={{ color: "#23408E" }}>
						Powered by Prescribe
					</Typography.Text>
					<br />
					<Typography.Link type="secondary" href="https://www.heyprescribe.com/" target="blank">
						www.heyprescribe.com
					</Typography.Link>
				</Col>
			</Row>
		</div>
	);
}
